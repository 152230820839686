import React, { useEffect, useState } from "react";
import style from "./Carousel.module.css";

interface CarouselItemProps {
  children: React.ReactNode;
  width?: string;
  linkTo?: string;
}

export const CarouselItem: React.FC<CarouselItemProps> = ({ children, width, linkTo }) => {
  const isValidUrl = (url: string | undefined): boolean => {
    return Boolean(url && (url.startsWith('http://') || url.startsWith('https://')));
  };

  const getLinkTarget = (url: string | undefined): string => {
    if (!url) return '_blank';
    try {
      const urlObj = new URL(url);
      const currentUrl = new URL(window.location.href);

      // 比較 origin (protocol + hostname + port)
      if (urlObj.origin !== currentUrl.origin) {
        return '_blank';
      }

      // 獲取路徑部分（移除最後的斜線）
      const urlPath = urlObj.pathname.replace(/\/$/, '');
      const currentPath = currentUrl.pathname.replace(/\/$/, '');

      // 檢查一個路徑是否是另一個的開頭
      if (urlPath.startsWith(currentPath) || currentPath.startsWith(urlPath)) {
        return '_self';
      }

      return '_blank';
    } catch {
      return '_blank';
    }
  };

  const content = (
    <div className={style.carouselItem} style={{ width: width }}>
      {children}
    </div>
  );

  if (isValidUrl(linkTo)) {
    return (
      <div className={style.carouselItem} style={{ width: width }}>
        <a 
          href={linkTo} 
          target={getLinkTarget(linkTo)}
          rel="noopener noreferrer"
        >
          {children}
        </a>
      </div>
    );
  }

  return content;
};

interface CarouselProps {
  children: React.ReactNode;
}

const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex: number): void => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateIndex(activeIndex + 1);
    }, 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  return (
    <div className={style.carousel}>
      <div
        className={style.innerCarousel}
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, idx) => {
          return React.cloneElement(child as React.ReactElement, {
            width: "100%",
          });
        })}
      </div>
      <div className={style.carouselButtonContainer}>
        {React.Children.map(children, (child, idx) => {
          return (
            <button 
              onClick={() => setActiveIndex(idx)}
              className={idx === activeIndex ? style.active : ''}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;