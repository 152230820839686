import React, { Fragment, useEffect, useState } from "react";
import LowerSideNav from "../SideNav/LowerSideNav";
import UpperSideNav from "../SideNav/UpperSideNav";
import style from "./Home.module.css";
import {
  Navigate,
  Outlet,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import pub_star from "../../assets/images/spot_a.png";
import footer from "../../assets/images/Logo.svg";
import useWindowSize from "../../utils/useWindowSize";
import * as CategoryAPI from "../../api/CategoryAPI";
import PageLoading from "../loading/PageLoading";
import { useTranslation, Trans } from "react-i18next";
import { LOGIN_RIDIRECT_PATHNAME } from "../../utils/Constant";
import { useDateString } from "./Layout";

type ContextType = { bannerList: any };

export default function Home() {
  const { width, height } = useWindowSize();
  const [displayCategory, setDisplayCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bannerList, setBannerList] = useState<any>([]);
  const [currentEvent, setCurrentEvent] = useState<any>([]);
  const [calendarEventHidden, setCalendarEventHidden] = useState(true);
  const { dateString } = useDateString();

  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    categoryList();
    fetchOrganization();
    fetchCurrentEvent();
  }, []);

  const categoryList = async () => {
    const categories = await CategoryAPI.getAll();
    setDisplayCategory(categories);
  };

  const fetchOrganization = async () => {
    const organization = fetch(
      process.env.REACT_APP_BACKEND + "marketingEvent/banner/allWithPriority"
    )
      .then((t) => t.json())
      .then((t) => t.filter((tt: any) => tt.bannerPosition != null))
      .then((t) => setBannerList(t));
  };

  const fetchCurrentEvent = () => {
    fetch(process.env.REACT_APP_BACKEND + "calendarEvent/current")
      .then((response) => response.json())
      .then((response2) => {
        //        console.log("response2", response2);
        const response3 = response2.map((item: any) => {
          return Object.assign({ isShow: false }, item);
        });
        setCurrentEvent(response3);
      });
  };

  const showCalendarBody = (id: number) => {
    const result = currentEvent.map((item: any) => {
      if (item.calendarEventId === id) {
        return { ...item, isShow: !item.isShow };
      } else {
        return { ...item };
      }
    });
    setCurrentEvent(result);
  };
  return (
    <div>
      <div className={style.thridHeader}>
        {/*
        {displayCategory.map((item: any) =>
          item.categoryId > 80000 && item.categoryId <= 80001 ? (
            <p onClick={() => navigate(`category/${item.categoryId}`)}>
              {item.categoryName}
            </p>
          ) : (
            <></>
          )
        )}
        */}
        <p onClick={() => navigate(`category/80001`)}>
          {t("category.80001")}
        </p>
        <p onClick={() => navigate("beginnerGuide")}>
          {t("footer.beginner")}
        </p>
      </div>
      <div className={style.homeContanier}>
        <div className={style.homeLeft}>
          <div className={style.homeUpperLeft}>
            <UpperSideNav props={displayCategory} />
          </div>
          <div className={style.homeLowerLeft}>
            <LowerSideNav props={displayCategory} />
          </div>
        </div>
        <div className={style.homeRight}>
          {loading ? <PageLoading /> : <Outlet context={{ bannerList }} />}

          {/* <img className={style.banner}src={random}/>
        <ButtonGroup/> */}
        </div>
        {width > 1381 ? (
          <div className={style.parterContainer}>
            <div className={style.parterInnerContainer}>
              <section className={style.calendar}>
                <div className={style.calendarHead}>
                  <p>{t("date", { date: new Date() })}</p>
                  <p>{dateString}</p>
                </div>
                {/* <div className={style.calendarHead}>
              </div> */}
                {currentEvent.map((event: any) => (
                  <Fragment key={event.calendarEventId}>
                    <div className={style.calendarBody}>
                      <div className={style.activityName}>
                        <div className={style.circle}></div>
                        <p className={style.activityNameC2}>{event.title}</p>
                        {event.isShow ? (
                          <span
                            style={{ color: "#47d5cd" }}
                            onClick={() =>
                              showCalendarBody(event.calendarEventId)
                            }
                          >
                            less
                          </span>
                        ) : (
                          <span
                            style={{ color: "#47d5cd" }}
                            onClick={() =>
                              showCalendarBody(event.calendarEventId)
                            }
                          >
                            more
                          </span>
                        )}
                      </div>
                      <div
                        style={event.isShow ? {} : { display: "none" }}
                        className={style.calendarBodyRow2}
                        dangerouslySetInnerHTML={{
                          __html: event ? event.description : "",
                        }}
                      ></div>
                    </div>
                  </Fragment>
                ))}
              </section>
              <div className={style.parterTitle}>
                <img src={pub_star}></img>
                <h4>&nbsp;合作夥伴專屬書櫃&nbsp;</h4>
                <img src={pub_star}></img>
              </div>
              {bannerList
                .filter((x: any) => x.bannerPosition === 1)
                .map((banner: any) => (
                  <div className={style.parterImgContainer}>
                    <img
                      className={style.parterImg}
                      src={
                        process.env.REACT_APP_BACKEND +
                        "file/" +
                        banner.bannerContent
                      }
                      onClick={() => {
                        isNaN(banner.bannerLinkTo)
                          ? window.location.replace(banner.bannerLinkTo)
                          : navigate(`category/${banner.bannerLinkTo}`);
                      }}
                    ></img>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export function useBanner() {
  return useOutletContext<ContextType>();
}
