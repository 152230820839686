import React from "react";
import "./settingPanel.css";
import OperationPanel from "../../panels/operationPanel/component";
import DropdownList from "../../../../components/Reader/readerSettings/dropdownList/component";
import DropdownListSingle from "../../../../components/Reader/readerSettings/dropdownList/component_single";
import ModeControl from "../../../../components/Reader/readerSettings/modeControl/component";
import SettingSwitch from "../../../../components/Reader/readerSettings/settingSwitch/component";
import SettingSwitchSingle from "../../../../components/Reader/readerSettings/settingSwitch/component_single";
import SliderList from "../../../../components/Reader/readerSettings/sliderList/component";
import TextToSpeech from "../../../../components/Reader/textToSpeech/component";
import ThemeList from "../../../../components/Reader/readerSettings/themeList/component";
import StorageUtil from "../../../../utils/reader/serviceUtils/storageUtil";
import { Tooltip } from "react-tippy";
import { connect } from "react-redux";

import { Trans } from "react-i18next";
import RecordLocation from "../../../../utils/reader/readUtils/recordLocation";

class SettingPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      time: 0,
      readerMode: StorageUtil.getReaderConfig("readerMode") || "double",
      isSettingLocked:
        StorageUtil.getReaderConfig("isSettingLocked") === "yes" ? true : false,
      currentChapterIndex: 0,
      isFixedLayout: false,
    };
  }

  componentDidMount() {
    // 檢查是否為 fixed layout
    const checkIfFixed = () => {
      const isFixed = 
          this.props.currentEpub &&
          this.props.currentEpub.rendition &&
          this.props.currentEpub.rendition._layout &&
          this.props.currentEpub.rendition._layout.name &&
          this.props.currentEpub.rendition._layout.name === "pre-paginated";

      this.setState({ isFixedLayout: isFixed }, () => {
        // 在 setState 的回調中處理配置
        if (isFixed) {
          StorageUtil.setReaderConfig("isApplyFont", "no");
          StorageUtil.setReaderConfig("isShowFontSize", "no");
        }
      });
    };

    // 立即檢查一次
    checkIfFixed();

    // 如果需要，也可以通過 metadata 再次確認
    if (this.props.currentEpub) {
        this.props.currentEpub.loaded.metadata.then(metadata => {
            if (metadata.layout === "pre-paginated") {
              this.setState({ isFixedLayout: true }, () => {
                StorageUtil.setReaderConfig("isApplyFont", "no");
                StorageUtil.setReaderConfig("isShowFontSize", "no");
              });
            }
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentEpub !== this.props.currentEpub) {
      // 當 epub 改變時重新檢查
      const isFixed = 
        this.props.currentEpub &&
        this.props.currentEpub.rendition &&
        this.props.currentEpub.rendition._layout &&
        this.props.currentEpub.rendition._layout.name &&
        this.props.currentEpub.rendition._layout.name === "pre-paginated";

      this.setState({ isFixedLayout: isFixed }, () => {
        // 在 setState 的回調中處理配置
        if (isFixed) {
          StorageUtil.setReaderConfig("isApplyFont", "no");
          StorageUtil.setReaderConfig("isShowFontSize", "no");
        }
      });

      // 如果需要，也可以通過 metadata 再次確認
      if (this.props.currentEpub) {
        this.props.currentEpub.loaded.metadata.then(metadata => {
          if (metadata.layout === "pre-paginated") {
            this.setState({ isFixedLayout: true }, () => {
              StorageUtil.setReaderConfig("isApplyFont", "no");
              StorageUtil.setReaderConfig("isShowFontSize", "no");
            });
          }
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    try {
      if (this.props.currentEpub.rendition) {
        const currentLocation =
          this.props.currentEpub.rendition.currentLocation();

        if (!currentLocation) return;

        if (!currentLocation.start) {
          return;
        }
        this.setState({
          currentChapterIndex: currentLocation.start.index,
        });
      }
    } catch (e) {
      console.log("err", e);
    }
  }

  handleJumpChapter2 = (pageNum) => {
    const section = this.props.currentEpub.section(pageNum);

    if (section && section.href) {
      this.props.currentEpub.rendition.display(section.href).then(() => {
        let percentage = RecordLocation.getCfi(this.props.currentBook.key)
          .percentage
          ? RecordLocation.getCfi(this.props.currentBook.key).percentage
          : 0;
        this.setState({ displayPercentage: percentage });
      });
    }
  };

  handleLock = () => {
    this.setState({ isSettingLocked: !this.state.isSettingLocked }, () => {
      StorageUtil.setReaderConfig(
        "isSettingLocked",
        this.state.isSettingLocked ? "yes" : "no"
      );
    });
  };

  getPageStr = () => {
    let readerConfig = localStorage.getItem("readerConfig");
    let readerConfigObj = JSON.parse(readerConfig);

    if (readerConfigObj.rm === "r" || readerConfigObj.rm === "o") {
      let _currentChapterIndex = this.state.currentChapterIndex;
      _currentChapterIndex = (this.state.currentChapterIndex + 1);
      
      // 暫時處理 pre-paginated 的 epub 時顯示頁數的問題
      // 不是 pre-paginated 的 epub 不顯示 頁數，因為 頁數 是指 Chapter
      if (
        this.props.currentEpub
        && this.props.currentEpub.rendition
        && this.props.currentEpub.rendition._layout
        && this.props.currentEpub.rendition._layout.name
        && this.props.currentEpub.rendition._layout.name === "pre-paginated"
      ) {
        
      } else {
        return;
      }
      // 暫時處理 pre-paginated 的 epub 時顯示頁數的問題 end

      return (
        <span className="pages_str">
          <Trans>Pages</Trans>
          <div
            className="currentCharterIndexBtn"
            contenteditable="true"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                //console.log("pages_str", this.props.currentEpub);
                let totalPageNum = 0;
                if (this.props.currentEpub.pageList) {
                  if (this.props.currentEpub.pageList.totalPages === 0) {
                    totalPageNum = Math.min(
                      this.props.currentEpub.locations.total,
                      this.props.currentEpub.rendition.book.spine.length
                    );
                  } else {
                    if (this.props.currentEpub.rendition) {
                      totalPageNum =
                        this.props.currentEpub.rendition.book.spine.length;
                    } else {
                    }
                  }
                  /*
                  totalPageNum = this.props.currentEpub.pageList.pageList.length === 0 
                    ? this.props.currentEpub.rendition
                      ? this.props.currentEpub.rendition.book.spine.length
                      : 0 
                    : this.props.currentEpub.locations.total 
                  */
                } else {
                  //totalPageNum = this.props.currentEpub.locations.total
                }
                event.preventDefault();
                let totalPage = totalPageNum; //this.props.currentEpub.locations.total//rendition?this.props.currentEpub.rendition.book.spine.length:0; // this.props.currentBook.locations.total
                let page = parseInt(event.target.innerHTML) - 1;
                if (page > totalPage) page = totalPage;
                this.setState({ currentChapterIndex: page });
                this.handleJumpChapter2(page);
              }
            }}
            onBlur={(event) => {
              try {
                let totalPage = this.props.currentEpub.rendition
                  ? this.props.currentEpub.rendition.book.spine.length
                  : 0;
                let page = parseInt(event.target.innerHTML);
                if (page > totalPage) page = totalPage;
                this.setState({ currentChapterIndex: page });
                this.handleJumpChapter2(page);
              } catch (e) {
                console.log(e);
              }
            }}
          >
            {_currentChapterIndex}
          </div>
          <span>
            /
            {/* this.props.currentEpub.pageList.pageList.length == 0 ? this.props.currentEpub.rendition?this.props.currentEpub.rendition.book.spine.length:0 : this.props.currentEpub.locations.total */}
            {this.props.currentEpub.pageList &&
            this.props.currentEpub.pageList.totalPages === 0
              ? Math.min(
                  this.props.currentEpub.locations.total,
                  this.props.currentEpub.rendition.book.spine.length
                )
              : this.props.currentEpub.rendition
              ? this.props.currentEpub.rendition.book.spine.length
              : 0}
          </span>
        </span>
      );
    }
  };

  render() {
    const { isFixedLayout } = this.state;  // 從 state 中解構出 isFixedLayout

    return (
      <div className="setting-panel-parent">
        <div className="setting-panel-title">
          <Trans>Reading Status & Option</Trans>
        </div>

        <Tooltip
          title={this.state.isSettingLocked ? "Unlock" : "Lock"}
          position="bottom"
          trigger="mouseenter"
          style={{ height: "30px", display: "inline-block", float: "left" }}
        >
          <span
            className={
              this.state.isSettingLocked
                ? "icon-lock lock-icon hidden"
                : "icon-unlock lock-icon hidden"
            }
            onClick={() => {
              this.handleLock();
            }}
          ></span>
        </Tooltip>

        <div className="setting-panel">
          {this.getPageStr()}
          {/*<OperationPanel {...{ time: this.state.time }} />*/}
          <OperationPanel {...{ time: this.props.time }} />
          <div></div>
          <ModeControl />
          <ThemeList />

          {/* 只在非 fixed layout 時顯示字體相關設置 */}
          {!isFixedLayout && (
            <>
              <SettingSwitchSingle propName={"isShowFontSize"} />
              <DropdownListSingle propName={"fontSize"} />
              <SliderList
                {...{
                  maxValue: 40,
                  minValue: 13,
                  mode: "fontSize",
                  minLabel: "13",
                  maxLabel: "40",
                  step: 1,
                  title: "Font Size",
                }}
              />
            </>
          )}
          {/*
          <TextToSpeech />
          */}
          {this.state.readerMode && this.state.readerMode === "double" && (
            <SliderList
              {...{
                maxValue: 80,
                minValue: 0,
                mode: "margin",
                minLabel: "0",
                maxLabel: "80",
                step: 5,
                title: "Margin",
              }}
            />
          )}

          <SliderList
            {...{
              maxValue: 20,
              minValue: 0,
              mode: "letterSpacing",
              minLabel: "0",
              maxLabel: "20",
              step: 1,
              title: "Letter Spacing",
            }}
          />

          <SliderList
            {...{
              maxValue: 60,
              minValue: 0,
              mode: "paraSpacing",
              minLabel: "0",
              maxLabel: "60",
              step: 1,
              title: "Paragraph Spacing",
            }}
          />

          {this.state.readerMode && this.state.readerMode !== "double" ? (
            <SliderList
              {...{
                maxValue: 3,
                minValue: 0.5,
                mode: "scale",
                minLabel: "0.5",
                maxLabel: "3",
                step: 0.1,
                title: "Page Width",
              }}
            />
          ) : null}
          <SliderList
            {...{
              maxValue: 2,
              minValue: 0.5,
              mode: "brightness",
              minLabel: "0.5",
              maxLabel: "2",
              step: 0.1,
              title: "Brightness",
            }}
          />
          {/*
          <DropdownList />
          */}
          <SettingSwitch />
          {!isFixedLayout && (
            <SettingSwitchSingle propName={"isApplyFont"} />
          )}
          {/*
          <SettingSwitchSingle propName={"isInvert"} />
          <SettingSwitchSingle propName={"isHideHeader"} />
          <SettingSwitchSingle propName={"isHideBackground"} />
          */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentEpub: state.book.currentEpub,
    currentBook: state.book.currentBook,
    locations: state.progressPanel.locations,
    isReading: state.book.isReading,
  };
};
const actionCreator = {};
export default connect(mapStateToProps, actionCreator)(SettingPanel);
