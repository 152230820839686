import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { DateTime } from "luxon";
import translationEN from "./assets/reader/locales/en/translation.json";
import translationTW from "./assets/reader/locales/tw/translation.json";
import translationCN from "./assets/reader/locales/cn/translation.json";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "tw",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: translationEN,
      },
      cn: {
        translation: translationCN,
      },
      tw: {
        translation: translationTW,
      },
    },
  }); // new usage
i18n.services.formatter.add("DATE_HUGE", (value, lng, options) => {
  return DateTime.fromJSDate(value)
    .setLocale("cn")
    .toLocaleString(DateTime.DATE_HUGE);
});
i18n.services.formatter.add("FORMAT", (value, lng, options) => {
  const fullDay = new Date(value).toLocaleString("en-us", { weekday: "long" });
  return `${fullDay}, ${DateTime.fromJSDate(value).toFormat("dd MMMM yyyy")}`;
});

export default i18n;
