import localforage from "localforage";
import React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import "../../../assets/reader/react.css";
import DialogBox from "../../../components/DialogBox/DialogBox";
import * as PATH from "../../../utils/Contants";
import Background from "../../../components/Reader/background/component";
import {
  ReaderLogControllerApi,
  GetLogUsingGETRequest,
  ReportRecordControllerApi,
  SaveReportRecordUsingPOSTReportTypeEnum,
  SaveReportRecordUsingPOSTBookTypeEnum,
} from "../../../fetcher";
import Bookmark from "../../../model/Bookmark";
import {
  handleBookmarks,
  handleFetchBookmarks,
  handleFetchBooks,
  handleFetchChapters,
  handleFetchNotes,
  handleFetchPercentage,
  handleNotes,
  handleReaderMode,
  handleReaderLog,
  handleRenderFunc,
  handleShowBookmark,
} from "../../../redux/actions/reader";
import { genApiConfig } from "../../../utils/Fetch-caller";
import { getArgsQuery } from "../../../utils/GlobalFunction";
import ReadingTime from "../../../utils/reader/readUtils/readingTime";
import RecordLocation from "../../../utils/reader/readUtils/recordLocation";
import {
  getIframeDoc,
  getIframeDoc2,
} from "../../../utils/reader/serviceUtils/docUtil";
import { EpubMouseEvent } from "../../../utils/reader/serviceUtils/mouseEvent";
import StorageUtil from "../../../utils/reader/serviceUtils/storageUtil";
import { withSocket } from "../../../utils/withSocket";
import EpubViewer from "../epubViewer/component";
import PageWidget from "../pageWidget/component";
import NavigationPanel from "../panels/navigationPanel/component";
import ProgressPanel from "../panels/progressPanel/component";
import SettingPanel from "../panels/settingPanel/component";
import "./index.css";

import MemberReducer from "../../../redux/reducers/MemberReducer";

let lock = false;

class Reader extends React.Component {
  messageTimer;
  tickTimer;
  rendition;
  mo;
  mo2;
  countReadingTime;
  mediaOverlay_timer;
  handleRenderBook_setTimeout_timer;

  constructor(props) {
    //console.log("Readerprops", props);
    super(props);
    this.state = {
      mo: null,
      mo2: null,
      mediaOverlay: false,
      isPlaying: false,
      render222: false,
      isOpenRightPanel:
        StorageUtil.getReaderConfig("isSettingLocked") === "yes" ? true : false,
      isOpenTopPanel: false,
      isOpenBottomPanel: false,
      isOpenLeftPanel:
        false, //StorageUtil.getReaderConfig("isNavLocked") === "yes" ? true : false,
      rendition: null,
      hoverPanel: "",
      scale: StorageUtil.getReaderConfig("scale") || 1,
      margin: parseInt(StorageUtil.getReaderConfig("margin")) || 30,
      time: ReadingTime.getTime(this.props.currentBook.key),
      isTouch: StorageUtil.getReaderConfig("isTouch") === "yes",
      isPreventTrigger:
        StorageUtil.getReaderConfig("isPreventTrigger") === "yes",
      readerMode: StorageUtil.getReaderConfig("readerMode") || "double",
      isHideBackground:
        StorageUtil.getReaderConfig("isHideBackground") === "yes",
      countPage: 0,
      countTime: 0,
      flagFirstgetLogUsingGET: true,
      flagStartLogUsingGET: false,
      flagIsMember: false,
    };
  }
  componentWillMount() {
    console.log("Reader", this.props.currentEpub);

    let _flag = window.location.href.contains("/epub/");
    if (_flag) {
      document.getElementById("root").classList.add("epub");
    }

    this.countReadingTime = setInterval(() => {
      //console.log("CP1 countReadingTime 000");
      // CTS update UI
      if (
        this.props.userInfo.member !== undefined
      ) {
        this.setState({
          ...this.state,
          flagIsMember: true,
        });
      } else {
        this.setState({
          ...this.state,
          flagIsMember: false,
        });
      }
      // CTS update UI end

      if (
        this.props.userInfo.member !== undefined
        && this.state.flagFirstgetLogUsingGET
      ) {
        this.setState({
          ...this.state,
          flagFirstgetLogUsingGET: false,
        });
        console.log("CP1 getLogUsingGET 111");
        new ReaderLogControllerApi(genApiConfig())
          .getLogUsingGET({
            memberId: this.props.userInfo.member.memberId,
            productId: getArgsQuery()?.bookId,
          })
          .then((res) => {
            if (res.content !== undefined) {
              let contentObj = JSON.parse(res.content);
              
              //console.log("CP1 contentObj", contentObj);

              if (contentObj !== undefined) {
                if (contentObj.viewcount === undefined) {
                  contentObj.viewcount = 1;
                } else {
                  contentObj.viewcount++;
                }

                if (
                  contentObj.startCfi === undefined
                  && this.props.currentEpub.rendition?.location?.start.cfi !== undefined
                ) {
                  contentObj.startCfi =
                    this.props.currentEpub.rendition?.location?.start.cfi;
                }

                if (
                  contentObj.endCfi === undefined
                  && this.props.currentEpub.rendition?.location?.end.cfi !== undefined
                ) {
                  contentObj.endCfi =
                    this.props.currentEpub.rendition?.location?.end.cfi;
                }

                if (contentObj.percentage === undefined) {
                  contentObj.percentage = 0;
                }

                if (contentObj.time === undefined) {
                  contentObj.time = 0;
                }

                if (contentObj.chapterViewCount === undefined) {
                  contentObj.chapterViewCount = "";
                }

                if (contentObj.percentage === undefined) {
                  contentObj.percentage = 0;
                }

                if (contentObj.notes === undefined) {
                  contentObj.notes = [];
                } else {
                  let noteArr = contentObj.notes ?? [];
                  this.props.handleNotes(noteArr);
                  localforage.setItem("notes", noteArr);
                }

                if (contentObj.bookmarks === undefined) {
                  contentObj.bookmarks = [];
                } else {
                  let bookmarkArr = contentObj.bookmarks ?? [];
                  this.props.handleBookmarks(bookmarkArr);
                  localforage.setItem("bookmarks", bookmarkArr);
                }

                if (
                  contentObj.currentCfi === undefined
                  && this.props.currentEpub.rendition?.location?.start.cfi !== undefined
                ) {
                  contentObj.currentCfi =
                    this.props.currentEpub.rendition?.location?.start.cfi;

                  /*
                  try {
                    let href = contentObj.currentCfi;
                    let _href =
                      this.props.currentEpub.spine.items.filter(
                        (item) => decodeURIComponent(item.href).indexOf(href) > -1
                      )[0].href || href;
                      console.log("_href", _href);
                    this.props.currentEpub.rendition.display(_href);
                  } catch (e) {
                    this.props.currentEpub.rendition.display(href);
                  }
                  */
                } else {
                }

                this.props.handleReaderLog(contentObj);
              }
            }
            
            this.setState({
              ...this.state,
              flagStartLogUsingGET: true,
            });
          })
          .catch((err) => {
            console.log("CP1 readerLog err", err);
          });
      }

      //TODO need change this to 60 if upload to production
      let _flagSaveLog = this.state.flagStartLogUsingGET;
      if (this.state.time % 10 === 0 && this.props.readerLog !== undefined) {
        let readerLogObj = this.props.readerLog;
        //console.log("CP1 readerLogObj 111", readerLogObj);
        if (readerLogObj.time === undefined) {
          readerLogObj.time = 10;
        } else {
          readerLogObj.time += 10;
        }

        // CTS
        if (
          readerLogObj.startCfi === undefined
          && this.props.currentEpub.rendition?.location?.start.cfi !== undefined
        ) {
          readerLogObj.startCfi =
            this.props.currentEpub.rendition?.location?.start.cfi;
        }

        if (
          readerLogObj.endCfi === undefined
          && this.props.currentEpub.rendition?.location?.end.cfi !== undefined
        ) {
          readerLogObj.endCfi =
            this.props.currentEpub.rendition?.location?.end.cfi;
        }

        //console.log("CP1 this.props.bookmarks", this.props.bookmarks);
        if (
          readerLogObj.bookmarks === undefined
          && this.props.bookmarks !== undefined
        ) {
          readerLogObj.bookmarks = this.props.bookmarks;
        } else if (
          this.props.bookmarks !== undefined
        ) {
          readerLogObj.bookmarks = this.props.bookmarks;
        }
        // CTS end

        this.props.handleReaderLog(readerLogObj);
        
        //console.log("CP1 readerLogObj 222", readerLogObj);
        if (_flagSaveLog) {
          console.log("CP1 saveLogUsingPOST 208");
          new ReaderLogControllerApi(genApiConfig())
            .saveLogUsingPOST({
              memberId: this.props.userInfo.member.memberId,
              productId: getArgsQuery()?.bookId,
              body: readerLogObj,
            })
            .then((res) => {
              let contentObj = JSON.parse(res.content);
              if (contentObj.time === undefined) {
                contentObj.time = 0;
              }
              this.props.handleReaderLog(contentObj);

              //console.log("save log res", res);
            })
            .catch((err) => {
              console.log("reader log err", err);
            });
        }
        // CTS
        // TODO
        // CTS end
      }
    }, 1000);

    this.props.handleFetchBookmarks();
    this.props.handleFetchPercentage(this.props.currentBook);
    this.props.handleFetchNotes();
    this.props.handleFetchBooks();
    this.props.handleFetchChapters(this.props.currentEpub);

    this.props.handleReaderMode(StorageUtil.getReaderConfig("rm"));

    let siteSerachParams = getArgsQuery();

    let bookObj = JSON.stringify({ bookId: siteSerachParams.bookId });
    this.props.setBookPage(bookObj);

    //====

    //console.log("this.props.currentBookthis.props.currentBook",this.props.currentBook, this.props.currentEpub)
  }

  componentWillReceiveProps(nextProps) {
    //console.log("Reader2", this.props);

    if (
      Object.keys(nextProps.currentEpub).length > 0 &&
      this.props.readerLog === undefined
    ) {
      //console.log("Reader2", this.props, this.countReadingTime);
      if (this.props.userInfo.member !== undefined) {
        new ReaderLogControllerApi(genApiConfig())
          .getLogUsingGET({
            memberId: this.props.userInfo.member.memberId,
            productId: getArgsQuery()?.bookId,
          })
          .then((res) => {
            let contentObj = JSON.parse(res.content);

            if (contentObj !== undefined) {
              if (contentObj.viewcount === undefined) {
                contentObj.viewcount = 1;
              } else {
                contentObj.viewcount++;
              }

              if (contentObj.startCfi === undefined) {
                contentObj.startCfi =
                  this.props.currentEpub.rendition.location.start.cfi;
              }

              if (contentObj.endCfi === undefined) {
                contentObj.endCfi =
                  this.props.currentEpub.rendition.location.end.cfi;
              }

              if (contentObj.percentage === undefined) {
                contentObj.percentage = 0;
              }

              if (contentObj.time === undefined) {
                contentObj.time = 0;
              }

              if (contentObj.chapterViewCount === undefined) {
                contentObj.chapterViewCount = "";
              }

              if (contentObj.percentage === undefined) {
                contentObj.percentage = 0;
              }

              if (contentObj.notes === undefined) {
                contentObj.notes = [];
              }

              if (contentObj.currentCfi === undefined) {
                contentObj.currentCfi =
                  this.props.currentEpub.rendition.location.start.cfi;
              } else {
              }

              this.props.handleReaderLog(contentObj);
            }

            new ReaderLogControllerApi(genApiConfig())
              .saveLogUsingPOST({
                memberId: this.props.userInfo.member.memberId,
                productId: getArgsQuery()?.bookId,
                body: contentObj,
              })
              .then((res) => {
                let contentObj = JSON.parse(res.content);
                this.props.handleReaderLog(contentObj);
                // console.log("save log res", res);
              })
              .catch((err) => {
                console.log("reader log err", err);
              });
          })
          .catch((err) => {
            console.log("reader log err", err);
          });
      }
    }
  }

  componentDidMount() {
    this.handleRenderBook2();

    //-----

    // handle Media Overlay play/stop status
    let _this = this;
    this.mediaOverlay_timer = setInterval(() => {
      let _flag_mo1 = false;
      if (_this.mo && _this.mo.audioplayer) {
        _flag_mo1 = _this.mo.audioplayer.isPlaying();
      }
      let _flag_mo2 = false;
      if (_this.mo2 && _this.mo2.audioplayer) {
        _flag_mo2 = _this.mo2.audioplayer.isPlaying();
      }
      let _flag = _flag_mo1 || _flag_mo2;
      _this.state.isPlaying = _flag;
    }, 300);
    // handle Media Overlay play/stop status end
  }

  componentWillUnmount() {
    clearInterval(this.tickTimer);
    clearInterval(this.countReadingTime);
    clearInterval(this.mediaOverlay_timer);
  }

  checkHasThisBookmark = () => {
    if (this.props.currentBook.format === "EPUB") {
      let cfi = RecordLocation.getCfi(this.props.currentBook.key).cfi;
      let flag = false;
      let bookmarkArr = this.props.bookmarks ?? [];
      bookmarkArr.forEach((bookmark) => {
        if (bookmark.cfi === cfi) {
          flag = true;
        }
      });
      return flag;
    }
  };
  getThisPageBookmark = () => {
    if (this.props.currentBook.format === "EPUB") {
      let cfi = RecordLocation.getCfi(this.props.currentBook.key).cfi;
      let flag = false;
      let bookmarkArr = this.props.bookmarks ?? [];
      let _bookmark;
      bookmarkArr.forEach((bookmark) => {
        if (bookmark.cfi === cfi) {
          flag = true;
          _bookmark = bookmark;
        }
      });
      return _bookmark ? _bookmark : null;
    }
  };

  handleAddBookmark = async () => {
    let bookKey = this.props.currentBook.key;
    let text = "";
    let chapter = "";
    let cfi = "";
    let percentage = 0;
    let _flagHasThisBookmark = false;
    if (this.props.currentBook.format === "EPUB") {
      const currentLocation =
        this.props.currentEpub.rendition.currentLocation();
      let chapterHref = currentLocation.start.href;
      chapter = "Unknown Chapter";
      console.log("this.props.flattenChapters", this.props);
      let currentChapter = this.props.flattenChapters.filter(
        (item) =>
          chapterHref.indexOf(item.href.split("#")[0]) > -1 ||
          item.href.split("#")[0].indexOf(chapterHref) > -1
      )[0];
      if (currentChapter) {
        chapter = currentChapter.label.trim(" ");
      }
      const cfibase = currentLocation.start.cfi
        .replace(/!.*/, "")
        .replace("epubcfi(", "");
      const cfistart = currentLocation.start.cfi
        .replace(/.*!/, "")
        .replace(/\)/, "");
      const cfiend = currentLocation.end.cfi
        .replace(/.*!/, "")
        .replace(/\)/, "");
      const cfiRange = `epubcfi(${cfibase}!,${cfistart},${cfiend})`;
      cfi = RecordLocation.getCfi(this.props.currentBook.key).cfi;
      if (this.checkHasThisBookmark()) {
        //return false;
        _flagHasThisBookmark = true;
      } else {
        let range = await this.props.currentEpub.getRange(cfiRange);
        if (range) {
          text = range.toString();
          percentage = RecordLocation.getCfi(this.props.currentBook.key)
            .percentage
            ? RecordLocation.getCfi(this.props.currentBook.key).percentage
            : 0;
        } else {
          console.log("range is null");
        }
      }
    } else {
      let bookLocation = RecordLocation.getHtmlLocation(bookKey);
      text = bookLocation.text;
      chapter = bookLocation.chapterTitle;
      percentage = bookLocation.percentage;

      cfi = JSON.stringify(bookLocation);
    }

    if (_flagHasThisBookmark) {
    } else {
      text = text
        .replace(/\s\s/g, "")
        .replace(/\r/g, "")
        .replace(/\n/g, "")
        .replace(/\t/g, "")
        .replace(/\f/g, "");

      let bookmark = new Bookmark(
        bookKey,
        cfi,
        text.substr(0, 200),
        percentage,
        chapter
      );
      //console.log("bookmark",bookmark);
      let bookmarkArr = this.props.bookmarks ?? [];
      bookmarkArr.push(bookmark);
      this.props.handleBookmarks(bookmarkArr);
      localforage.setItem("bookmarks", bookmarkArr);
      
      //=====
      // update ReadrLog
      let _bookmarks = bookmarkArr;
      this.handleSaveReaderLogBookmark(_bookmarks);
    }

    //=====
    // check has bookmark
    if (_flagHasThisBookmark) {
      this.setState({ isBookmark: false });
      //alert("Add Successfully");
      this.props.handleShowBookmark(false);

      try {
        this.handleDeleteBookmark();
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({ isBookmark: true });
      //alert("Add Successfully");
      this.props.handleShowBookmark(true);
    }
  };
  handleDeleteBookmark = () => {
    //console.log("[handleDeleteBookmark]");
    let deleteItems = this.props.bookmarks;
    let deleteFunc = this.props.handleFetchBookmarks;
    let _bookmark = this.getThisPageBookmark();
    if (_bookmark) {
    } else {
      return;
    }
    //console.log("[handleDeleteBookmark] continue");
    deleteItems.forEach((item, index) => {
      //console.log("[handleDeleteBookmark]", item, index, _bookmark);

      if (item.key === _bookmark.key) {
        deleteItems.splice(index, 1);
        if (deleteItems.length === 0) {
          localforage
            .removeItem("bookmarks")
            .then(() => {
              deleteFunc();
              //alert("删除成功");
            })
            .catch(() => {
              console.log("删除失败");
            });
        } else {
          localforage
            .setItem("bookmarks", deleteItems)
            .then(() => {
              deleteFunc();
              //alert("删除成功");
            })
            .catch(() => {
              console.log("修改失败");
            });
        }
      }
    });
    this.props.handleBookmarks(deleteItems);
    
    //=====
    // update ReadrLog
    let _bookmarks = deleteItems;
    this.handleSaveReaderLogBookmark(_bookmarks);
  };
  handleSaveReaderLogBookmark = (bookmarks) => {
    //=====
    // update ReadrLog
    //console.log("CP1 this.props.bookmarks", this.props.bookmarks);
    //console.log("CP1 this.props.readerLog", this.props.readerLog);
    let readerLogObj = this.props.readerLog;
    readerLogObj.bookmarks = bookmarks;
    new ReaderLogControllerApi(genApiConfig())
      .saveLogUsingPOST({
        memberId: this.props.userInfo.member.memberId,
        productId: getArgsQuery()?.bookId,
        body: readerLogObj,
      })
      .then((res) => {
        // console.log("save log res", res);
      })
      .catch((err) => {
        console.log("reader log err", err);
      });
  };
  
  handleSaveReaderLogNote = (notes) => {
    //=====
    // update ReadrLog
    //console.log("CP1 this.props.readerLog", this.props.readerLog);
    let readerLogObj = this.props.readerLog;
    readerLogObj.notes = notes;
    new ReaderLogControllerApi(genApiConfig())
      .saveLogUsingPOST({
        memberId: this.props.userInfo.member.memberId,
        productId: getArgsQuery()?.bookId,
        body: readerLogObj,
      })
      .then((res) => {
        // console.log("save log res", res);
      })
      .catch((err) => {
        console.log("reader log err", err);
      });
  };

  handleRenderBook2 = () => {
    let epub = this.props.currentEpub;
    //console.log("cover2222", epub);
    let _this = this;

    //checkCover();

    let checkCoverInterval = setInterval(function () {
      //console.log("checkCoverInterval", epub);
      //  && typeof epub.archive.urlCache !== undefined
      if (typeof epub.cover != "undefined") {
        clearInterval(checkCoverInterval);
        //console.log(epub);
        var coverPathKey = epub.cover;
        //console.log(coverPathKey);
        var urlCover = epub.archive.urlCache[coverPathKey];
        //console.log(coverPathKey);
        //console.log(epub.archive);
        //console.log(JSON.stringify(epub.archive.urlCache));
        //console.log(urlCover);
        if (urlCover === undefined) {
          _this.width = 1200;
          _this.height = 1800;

          //=====

          _this.handleRenderBook();
          _this.props.handleRenderFunc(_this.handleRenderBook);
          var doit;
          window.addEventListener("resize", () => {
            clearTimeout(doit);
            doit = setTimeout(_this.handleRenderBook, 100);
          });

          _this.tickTimer = setInterval(() => {
            let time = _this.state.time;
            time += 1;
            _this.setState({ time });

            //解决快速翻页过程中图书消失的bug
            let renderedBook = document.querySelector(".epub-view");
            if (
              renderedBook &&
              !renderedBook.innerHTML &&
              _this.state.readerMode !== "scroll"
            ) {
              _this.handleRenderBook();
            }
          }, 1000);
        } else {
          var coverImg = new Image();
          coverImg.src = urlCover; // this.props.currentBook.cover;
          coverImg.onload = function () {
            var w = coverImg.width;
            var h = coverImg.height;

            //console.log("NEW IMAGE width", w);
            //console.log("NEW IMAGE height: ", h);
            try {
              _this.width = coverImg.width;
              _this.height = coverImg.height;

              //=====

              _this.handleRenderBook();
              _this.props.handleRenderFunc(_this.handleRenderBook);
              var doit;
              window.addEventListener("resize", () => {
                clearTimeout(doit);
                doit = setTimeout(_this.handleRenderBook, 100);
              });

              _this.tickTimer = setInterval(() => {
                let time = _this.state.time;
                time += 1;
                _this.setState({ time });
                //TODO
                //update reading time ...

                //解决快速翻页过程中图书消失的bug
                let renderedBook = document.querySelector(".epub-view");
                if (
                  renderedBook &&
                  !renderedBook.innerHTML &&
                  _this.state.readerMode !== "scroll"
                ) {
                  _this.handleRenderBook();
                }
              }, 1000);
            } catch (err) {
              console.log(err);
            }
          };
        }
        // } else if(){
      } else {
        clearInterval(checkCoverInterval);
        _this.width = window.innerWidth / 2;
        _this.height = window.innerHeight;
        //console.log("_this.width ", _this.width, _this.height);
        _this.handleRenderBook();
        _this.props.handleRenderFunc(_this.handleRenderBook);
        var doit;
        window.addEventListener("resize", () => {
          clearTimeout(doit);
          doit = setTimeout(_this.handleRenderBook, 100);
        });

        _this.tickTimer = setInterval(() => {
          let time = _this.state.time;
          time += 1;
          _this.setState({ time });

          //解决快速翻页过程中图书消失的bug
          let renderedBook = document.querySelector(".epub-view");
          if (
            renderedBook &&
            !renderedBook.innerHTML &&
            _this.state.readerMode !== "scroll"
          ) {
            _this.handleRenderBook();
          }
        }, 1000);
      }
    }, 1000);
  };

  initMediaOverlay_for_handleRenderBook = () => {
    if (this.state.mediaOverlay) {
      //TODO Prepare media overlay
      let _this = this;
      setTimeout(function () {
        let manifestObjArray = Object.entries(
          _this.props.currentEpub.packaging.manifest
        );
        let filteredObj = [];
        try {
          //console.log("manifestObjArray", manifestObjArray, _this.rendition);
          filteredObj = manifestObjArray.filter(
            ([key, value]) =>
              value.href.substring(0, value.href.lastIndexOf(".")) ===
                _this.rendition.location.start.href.substring(
                  0,
                  _this.rendition.location.start.href.lastIndexOf(".")
                ) && value.href.contains("smil")
          );
        } catch (ex) {}
        if (filteredObj.length > 0) {
          try {
            _this.mo.pause();
          } catch (e) {}
          try {
            _this.mo2.pause();
          } catch (e) {}

          let startCfi = _this.rendition.location.start.cfi
            .replace("epubcfi(", "")
            .replace(")", "");
          let splitItems = startCfi.split("/");
          let lastSplitItem = splitItems[splitItems.length - 2];
          var regExp = /\[([^)]+)\]/;
          var matches = regExp.exec(lastSplitItem);
        }
      }, 1000);
    }
  };

  handleRenderBook_setTimeout = () => {
    // CTS checking
    let doc = getIframeDoc();
    console.log("getIframeDoc", doc);
    let _this = this;
    if (doc) {

    } else {
      clearTimeout(this.handleRenderBook_setTimeout_timer);
      this.handleRenderBook_setTimeout_timer = setTimeout(function () {
        _this.handleRenderBook_setTimeout();
      }, 300);
      return;
    }
    // CTS checking end
    
    // handle some epub ltr position problem
    if (
      StorageUtil.getReaderConfig("readerMode") === "single" ||
      StorageUtil.getReaderConfig("readerMode") === "scroll"
    ) {
    } else {
      //=====
      try {
        //console.log("this.props.currentEpub", _this.props.currentEpub);
        let _target = document.getElementsByClassName("epub-container")[0];
        let _flag = _target.dir === "ltr";
        if (_flag) {
          //console.log(
          //  "epubReader handleRenderBook BBB setTimeout start",
          //  _target.getBoundingClientRect().width
          //);
          let ___w = _target.getBoundingClientRect().width / 2;
          //document.querySelector('.epub-view[ref="0"]').style.marginLeft =  ___w + "px";
        }
      } catch (err) {
        console.log("err", err);
      }
      //console.log("handleRenderBook BBB END");
    }
    // handle some epub ltr position problem end

    //console.log("epub.rendition", epub.rendition);
    let epub = this.props.currentEpub;
    console.log("epub.rendition._layout", epub.rendition._layout); //, JSON.stringify(epub.rendition._layout) );
    console.log("epub.rendition.settings", epub.rendition.settings); //, JSON.stringify(epub.rendition.settings) );

    let _minSpreadWidth = 768;

    let _w = "100%";
    let _h = "100%";
    let _w1 = -1;
    let _h1 = -1;
    let _w_state = -1;
    let _h_state = -1;

    //=====
    console.log(
      "epub.packaging.metadata.spread=[" +
        epub.packaging.metadata.spread +
        "]"
    );
    console.log(
      "StorageUtil.getReaderConfig:" +
        StorageUtil.getReaderConfig("readerMode")
    );
    if (epub.packaging.metadata.spread === "none") {
      //console.log("DDDDD");
      if (StorageUtil.getReaderConfig("readerMode") !== "single") {
        StorageUtil.setReaderConfig("readerMode", "single");
        window.location.reload();
      }

      console.log(
        "EEEEE",
        epub.rendition.settings,
        JSON.stringify(epub.rendition.settings)
      );
      //let doc = getIframeDoc();
      // console.log("getIframeDoc", doc);
      // console.log(doc.querySelector("meta[name=viewport]"));
      // console.log(
      //   doc.querySelector("meta[name=viewport]").getAttribute("content")
      // );
      let _temp_arr = doc
        .querySelector("meta[name=viewport]")
        ?.getAttribute("content")
        ?.split(",") || [];
      let _t_w = -1;
      let _t_h = -1;
      if (_temp_arr && _temp_arr.length > 0) {
        _temp_arr.forEach((str) => {
          if (str.toLocaleLowerCase().contains("width")) {
            _t_w = parseInt(
              str.trim().split("=")[1].replace('"', "").replace("'", "")
            );
          } else if (str.toLocaleLowerCase().contains("height")) {
            _t_h = parseInt(
              str.trim().split("=")[1].replace('"', "").replace("'", "")
            );
          }
        });
      }
      let _rw = (window.innerWidth * 0.9) / _t_w;
      let _rh = (window.innerHeight * 0.9) / _t_h;
      let _r = Math.min(_rw, _rh);
      //console.log(_t_w, _t_h, _rw, _rh, _r);
      _w_state = _t_w * _r;
      _h_state = _t_h * _r;

      //_h_state = _w_state * _ori_h / _ori_w;
      _this.rendition.resize(_w_state, _h_state);
      //console.log("BBBBBB", _w_state, _h_state);
    } else if (
      epub.packaging.metadata.spread === "" ||
      epub.packaging.metadata.spread === "landscape" ||
      epub.packaging.metadata.spread === "both"
    ) {
      if (
        typeof StorageUtil.getReaderConfig("readerMode") === "undefined"
      ) {
        StorageUtil.setReaderConfig("readerMode", "double");
      }
      if (
        StorageUtil.getReaderConfig("readerMode") === "double" &&
        epub.rendition._layout.name === "pre-paginated"
      ) {
        //let doc = getIframeDoc();
        let _temp_arr = doc
          .querySelector("meta[name=viewport]")
          ?.getAttribute("content")
          ?.split(",") || [];
        let _t_w = -1;
        let _t_h = -1;
        if (_temp_arr && _temp_arr.length > 0) {
          _temp_arr.forEach((str) => {
            if (str.toLocaleLowerCase().contains("width")) {
              _t_w = parseInt(
                str.trim().split("=")[1].replace('"', "").replace("'", "")
              );
            } else if (str.toLocaleLowerCase().contains("height")) {
              _t_h = parseInt(
                str.trim().split("=")[1].replace('"', "").replace("'", "")
              );
            }
          });
        }
        _t_w = _t_w * 2;
        let _rw = (window.innerWidth * 0.9) / _t_w;
        let _rh = (window.innerHeight * 0.9) / _t_h;
        let _r = Math.min(_rw, _rh);
        //console.log(_t_w, _t_h, _rw, _rh, _r);
        _w_state = _t_w * _r;
        _h_state = _t_h * _r;

        //_h_state = _w_state * _ori_h / _ori_w;
        _this.rendition.resize(_w_state, _h_state);
      } else {
        //let doc = getIframeDoc();
        // console.log(doc.querySelector("meta[name=viewport]"));
        // console.log(
        //   doc.querySelector("meta[name=viewport]").getAttribute("content")
        // );
        let _temp_arr = doc
          .querySelector("meta[name=viewport]")
          ?.getAttribute("content")
          ?.split(",") || [];
        let _t_w = -1;
        let _t_h = -1;
        if (_temp_arr && _temp_arr.length > 0) {
          _temp_arr.forEach((str) => {
            if (str.toLocaleLowerCase().contains("width")) {
              _t_w = parseInt(
                str.trim().split("=")[1].replace('"', "").replace("'", "")
              );
            } else if (str.toLocaleLowerCase().contains("height")) {
              _t_h = parseInt(
                str.trim().split("=")[1].replace('"', "").replace("'", "")
              );
            }
          });
        }

        let _rw = (window.innerWidth * 0.9) / _t_w;
        let _rh = (window.innerHeight * 0.9) / _t_h;
        let _r = Math.min(_rw, _rh);
        //console.log(_t_w, _t_h, _rw, _rh, _r);
        _w_state = _t_w * _r;
        _h_state = _t_h * _r;

        //_h_state = _w_state * _ori_h / _ori_w;
        _this.rendition.resize(_w_state, _h_state);
      }
    } else if (
      epub.packaging.metadata.spread === "auto" ||
      epub.packaging.metadata.spread === "landscape" ||
      epub.packaging.metadata.spread === "both"
    ) {
      if (
        StorageUtil.getReaderConfig("readerMode") === "double" &&
        epub.rendition._layout.name === "pre-paginated"
      ) {
        //let doc = getIframeDoc();
        let _temp_arr = doc
          .querySelector("meta[name=viewport]")
          ?.getAttribute("content")
          ?.split(",") || [];
        let _t_w = -1;
        let _t_h = -1;
        if (_temp_arr && _temp_arr.length > 0) {
          _temp_arr.forEach((str) => {
            if (str.toLocaleLowerCase().contains("width")) {
              _t_w = parseInt(
                str.trim().split("=")[1].replace('"', "").replace("'", "")
              );
            } else if (str.toLocaleLowerCase().contains("height")) {
              _t_h = parseInt(
                str.trim().split("=")[1].replace('"', "").replace("'", "")
              );
            }
          });
        }
        _t_w = _t_w * 2;
        let _rw = (window.innerWidth * 0.9) / _t_w;
        let _rh = (window.innerHeight * 0.9) / _t_h;
        let _r = Math.min(_rw, _rh);
        //console.log(_t_w, _t_h, _rw, _rh, _r);
        _w_state = _t_w * _r;
        _h_state = _t_h * _r;

        //_h_state = _w_state * _ori_h / _ori_w;
        _this.rendition.resize(_w_state, _h_state);
      }
    } else {
      // check mobile
      /*
      let _flag_mobile = window.innerWidth <= 428;
      if (_flag_mobile) {
        if (StorageUtil.getReaderConfig("readerMode") === "double") {
          StorageUtil.setReaderConfig("readerMode", "single")
          StorageUtil.setReaderConfig("isHideBackground", "no")
          window.location.reload();
        }
      } else {
        if (StorageUtil.getReaderConfig("readerMode") !== "double") {
          StorageUtil.setReaderConfig("readerMode", "double")
          StorageUtil.setReaderConfig("isHideBackground", "no")
          window.location.reload();
        }
      }
      */
    }
    //=====

    //console.log("[epubReader][resize] StorageUtil.getReaderConfig:" + StorageUtil.getReaderConfig("readerMode"));
    if (
      StorageUtil.getReaderConfig("readerMode") === "single" ||
      StorageUtil.getReaderConfig("readerMode") === "scroll"
    ) {
      //console.log("[epubReader][resize] single");

      //console.log("epub.rendition._layout.settings", epub.rendition._layout.settings, JSON.stringify(epub.rendition._layout.settings) );

      //console.log("epub.rendition._layout.settings.layout", epub.rendition._layout.settings.layout);
      if (epub.rendition._layout.settings.layout === "reflowable") {
        _w_state = window.innerWidth * 0.8;
        _h_state = window.innerHeight * 0.8;
        _this.rendition.resize(
          window.innerWidth * 0.8,
          window.innerHeight * 0.8
        );
      } else {
        _w = "100%";
        _h = "100%";
        //=====
        try {
          let __width = parseInt(epub.rendition._layout.width); //parseInt(epub.rendition.settings.width);
          let __height = parseInt(epub.rendition._layout.height); //parseInt(epub.rendition.settings.height);

          //console.log("[epubReader][resize] __width", __width);
          //console.log("[epubReader][resize] __height", __height);
          if (__width) {
            _w = __width + "px";
            _w1 = __width; //(this.width * 2);
          }
          if (__height) {
            _h = __height + "px";
            _h1 = __height;
          }
        } catch (err) {}
        //console.log("[epubReader][resize] _w", _w, "_h", _h);

        let _w2 = window.innerWidth * 0.8;
        let _h2 = window.innerHeight * 0.8;
        let _r = 1;
        if (_w1 > _h1) {
          _r = _w2 / _w1;
          _w_state = _w1 * _r;
          _w = _w1 * _r + "px";
          _h_state = _h1 * _r;
          _h = _h1 * _r + "px";
        } else {
          _r = _h2 / _h1;
          _w_state = _w1 * _r;
          _w = _w1 * _r + "px";
          _h_state = _h1 * _r;
          _h = _h1 * _r + "px";
        }

        _minSpreadWidth = _w1;
        //console.log("[epubReader][resize] AAA, _w", _w, "_h", _h);
      }
    } else {
      try {
        let __width = parseInt(epub.rendition._layout.width); //parseInt(epub.rendition.settings.width);
        let __height = parseInt(epub.rendition._layout.height); //parseInt(epub.rendition.settings.height);
        //console.log("[epubReader][resize]", __width, __height);
        if (__width) {
          _w_state = __width * 2;
          _w = __width * 2 + "px";
          _w1 = __width; //(this.width * 2);
        }
        if (__height) {
          _h_state = __height;
          _h = __height + "px";
          _h1 = __height;
        }
      } catch (err) {}
      //console.log("[epubReader][resize] _w", _w, "_h", _h);
      _w1 -= 24;
      //console.log("[epubReader][resize] _w", _w, "_h", _h);

      let _w2 = window.innerWidth * 0.9;
      _w2 = _w2 / 2;
      let _h2 = window.innerHeight * 0.9;
      let _r = 1;
      if (_w1 > _h1) {
        //console.log("[epubReader][resize] _w1 > _h1", _w1, _h1);
        _r = _w2 / _w1;
        _w_state = _w1 * 2 * _r;
        _w = _w1 * 2 * _r + "px";
        _h_state = _h1 * _r;
        _h = _h1 * _r + "px";
      } else {
        //console.log("[epubReader][resize] _w1 < _h1", _w1, _h1);
        _r = _h2 / _h1;
        _w_state = _w1 * 2 * _r;
        _w = _w1 * 2 * _r + "px";
        _h_state = _h1 * _r;
        _h = _h1 * _r + "px";
      }

      _minSpreadWidth = _w1 / 2;
      //console.log("[epubReader][handlresizeeRenderBook] BBB, _w * 2", _w, "_h", _h);
    }
    _this.state.width = _w_state;
    _this.state.height = _h_state;
    //console.log("[epubReader][resize] _w_state=" + _w_state + ", _h_state=" + _h_state);
    //console.log("[epubReader][resize] _this.state.width=" + _this.state.width + ", _this.state.height=" + _this.state.height);



    // Start get ReaderLog
    /*
    console.log("CP1 ReaderLogControllerApi getLogUsingGET", this.props);
    new ReaderLogControllerApi(genApiConfig())
    .getLogUsingGET({
      memberId: this.props.userInfo.member.memberId,
      productId: getArgsQuery()?.bookId,
    })
    .then((res) => {
      if (res.content !== undefined) {
        let contentObj = JSON.parse(res.content);
        this.props.handleReaderLog(contentObj);
      }
    })
    .catch((err) => {
      console.log("readerLog err", err);
    });
    */
    // Start get ReaderLog end
  };

  handleRenderBook = () => {
    let page = document.querySelector("#page-area");
    let epub = this.props.currentEpub;
    let _this = this;
    epub.ready.then(() => {
      //alert("finish rendition");

      /*
      setTimeout(function () {
      */
        _this.setState({ rendition: _this.rendition });
        EpubMouseEvent(
          _this.rendition,
          _this.state.readerMode,
          _this.state.render222
        ); // 绑定事件
        if (epub.rendition.settings.spread == "none") {
          if (StorageUtil.getReaderConfig("readerMode") === "double") {
            StorageUtil.setReaderConfig("readerMode", "single");
            window.location.reload();
          }
        } else if (epub.rendition.settings.spread == true) {
          if (StorageUtil.getReaderConfig("readerMode") !== "double") {
            StorageUtil.setReaderConfig("readerMode", "double");
            window.location.reload();
          }
        }
        //Not open from the bookshelf
        if (this.props.currentBook.name !== epub.packaging.metadata.title) {
          alert("請從書架開啟");
          window.location.href = "/";
        }

        // check have mediaOverlay
        // now here have 2 format mediaOverlay ePub

        if (
          epub.packaging.metadata.media_active_class === "correct" ||
          epub.packaging.metadata.media_active_class ===
            "-epub-media-overlay-active"
        ) {
          // mediaOverlay case 1
          this.state.mediaOverlay = true;

          //-----
          this.initMediaOverlay_for_handleRenderBook();
        } else if (epub.packaging.metadata.media_active_class === "") {
          // mediaOverlay case 2
          let _this = this;
          setTimeout(function () {
            let manifestObjArray = Object.entries(
              _this.props.currentEpub.packaging.manifest
            );
            let filteredObj = [];
            try {
              //console.log("manifestObjArray", manifestObjArray, _this.rendition);
              filteredObj = manifestObjArray.filter(([key, value]) => {
                // case 2
                // have overlay value
                let _flag_case2 = false;
                if (value.overlay && value.overlay.length > 0) {
                  _flag_case2 = true;
                }
                // case 2 end
                return _flag_case2;
              });
            } catch (ex) {}
            if (filteredObj.length > 0) {
              _this.state.mediaOverlay = true;
            }

            //-----
            _this.initMediaOverlay_for_handleRenderBook();
          }, 1000);
        }
        // mediaOverlay case 2 end

        // check have mediaOverlay end

        // wait to get globalLayoutProperties
        //this.rendition.resize(window.innerWidth, window.innerHeight);

        //console.log("ready to setTimeout");
        //let _this = this;
        clearTimeout(this.handleRenderBook_setTimeout_timer);
        this.handleRenderBook_setTimeout_timer = setTimeout(function () {
          _this.handleRenderBook_setTimeout();
        }, 300);

        //console.log("[epubReader][handleRenderBook] epub.ready.then end");
      /*
      }, 1000);
      */
    });
    //console.log(epub);
    if (!page) return;
    if (page.innerHTML) {
      page.innerHTML = "";
    }
    let _minSpreadWidth = 768;

    //console.log('handleRenderBook epub', epub)
    let _w = "100%";
    let _h = "100%";
    let _w1 = -1;
    let _h1 = -1;
    let _w_state = -1;
    let _h_state = -1;

    if (
      StorageUtil.getReaderConfig("readerMode") === "single" ||
      StorageUtil.getReaderConfig("readerMode") === "scroll"
    ) {
      _w = "100%";
      _h = "100%";
      //=====
      try {
        let __width = parseInt(this.width);
        let __height = parseInt(this.height);
        //console.log(__width);
        //console.log(__height);
        if (__width) {
          _w = __width + "px";
          _w1 = __width; //(this.width * 2);
        }
        if (__height) {
          _h = __height + "px";
          _h1 = __height;
        }
      } catch (err) {}
      //console.log("_w", _w, "_h", _h);

      let _w2 = window.innerWidth * 0.8;
      let _h2 = window.innerHeight * 0.8;
      let _r = 1;
      if (_w1 > _h1) {
        _r = _w2 / _w1;
        _w_state = _w1 * _r;
        _w = _w1 * _r + "px";
        _h_state = _h1 * _r;
        _h = _h1 * _r + "px";
      } else {
        _r = _h2 / _h1;
        _w_state = _w1 * _r;
        _w = _w1 * _r + "px";
        _h_state = _h1 * _r;
        _h = _h1 * _r + "px";
      }

      _minSpreadWidth = _w1;
      //console.log("AAA, _w", _w, "_h", _h);
    } else {
      try {
        let __width = parseInt(this.width);
        let __height = parseInt(this.height);
        //console.log("handleRenderBook", __width, __height);
        if (__width) {
          _w_state = __width * 2;
          _w = __width * 2 + "px";
          _w1 = __width; //(this.width * 2);
        }
        if (__height) {
          _h_state = __height;
          _h = __height + "px";
          _h1 = __height;
        }
      } catch (err) {}
      //console.log("_w", _w, "_h", _h);
      _w1 -= 24;
      //console.log("_w", _w, "_h", _h);

      let _w2 = window.innerWidth * 0.9;
      _w2 = _w2 / 2;
      let _h2 = window.innerHeight * 0.9;
      let _r = 1;
      if (_w1 > _h1) {
        _r = _w2 / _w1;
        _w_state = _w1 * 2 * _r;
        _w = _w1 * 2 * _r + "px";
        _h_state = _h1 * _r;
        _h = _h1 * _r + "px";
      } else {
        _r = _h2 / _h1;
        _w_state = _w1 * 2 * _r;
        _w = _w1 * 2 * _r + "px";
        _h_state = _h1 * _r;
        _h = _h1 * _r + "px";
      }

      _minSpreadWidth = _w1 / 4; //2;
      //console.log("BBB, _minSpreadWidth", _minSpreadWidth);
    }
    this.state.width = _w_state;
    this.state.height = _h_state;
    //console.log("fffff_w=" + _w + ", _h=" + _h);
    // console.log("_w_state=" + _w_state + ", _h_state=" + _h_state);
    // console.log(
    //   "this.state.width=" +
    //     this.state.width +
    //     ", this.state.height=" +
    //     this.state.height
    // );
    let option = {
      manager: "default",
      flow: this.state.readerMode === "scroll" ? "scrolled" : "auto",
      width: window.innerWidth * 0.9, //_w,
      height: _h,
      snap: true,
      minSpreadWidth: _minSpreadWidth,
      allowScriptedContent: true,
      spread:
        StorageUtil.getReaderConfig("readerMode") === "single" ? "none" : "",
    };

    if (StorageUtil.getReaderConfig("isVertical") === "yes") {
      _minSpreadWidth = "none";
      option.spread = "none";
      option.height = "100";
      //delete option.height;
      //console.log(option);
      //StorageUtil.setReaderConfig("readerMode", "scroll")
      //StorageUtil.setReaderConfig("isHideBackground", "no")
      //window.location.reload();
    } else {
      if (window.innerWidth * 0.9 < _minSpreadWidth) {
        _minSpreadWidth = "none";
        option.spread = "none";
      }
    }
    this.setState({ rendition: null }, () => {
      window.rangy.init(); // 初始化
      this.rendition = epub.renderTo(page, option);
      if (this.state.render222 == false) {
        this.state.render222 = true;
      }

      console.log(
        "epub.rendition.settings.spread",
        epub.rendition.settings.spread
      );

      //======
    });
  };

  //进入阅读器
  handleEnterReader = (position) => {
    //控制上下左右的菜单的显示
    switch (position) {
      case "right":
        this.setState({
          isOpenRightPanel: this.state.isOpenRightPanel ? false : true,
        });
        break;
      case "left":
        this.setState({
          isOpenLeftPanel: this.state.isOpenLeftPanel ? false : true,
        });
        break;
      case "top":
        this.setState({
          isOpenTopPanel: this.state.isOpenTopPanel ? false : true,
        });
        break;
      case "bottom":
        this.setState({
          isOpenBottomPanel: this.state.isOpenBottomPanel ? false : true,
        });
        break;
      default:
        break;
    }
  };
  //退出阅读器
  handleLeaveReader = (position) => {
    //控制上下左右的菜单的显示
    switch (position) {
      case "right":
        if (StorageUtil.getReaderConfig("isSettingLocked") === "yes") {
          break;
        } else {
          this.setState({ isOpenRightPanel: false });
          break;
        }
      case "left":
        if (StorageUtil.getReaderConfig("isNavLocked") === "yes") {
          break;
        } else {
          this.setState({ isOpenLeftPanel: false });
          break;
        }
      case "top":
        this.setState({ isOpenTopPanel: false });
        break;
      case "bottom":
        this.setState({ isOpenBottomPanel: false });
        break;
      default:
        break;
    }
  };

  nextPageControl = () => {
    if (lock) return;
    if (
      this.props.currentEpub.rendition.location &&
      this.props.currentEpub.rendition.location.atEnd
    ) {
      return;
    }
    lock = false;
    this.state.rendition.next().then(() => {
      let _this = this;
      try {
        _this.mo.pause();
      } catch (e) {}
      try {
        _this.mo2.pause();
      } catch (e) {}
      this.state.isPlaying = false;
      setTimeout(function () {
        if (_this.state.mediaOverlay) {
          _this.listenThisPage();
        }
      }, 500);
      lock = false;
      return false;
    });
  };

  nextPage = () => {
    // stop media overlay audio
    try {
      this.mo.pause();
    } catch (e) {}
    try {
      this.mo2.pause();
    } catch (e) {}
    // stop media overlay audio end
    
    // stop 
    if (
      window.speechSynthesis
      && window.speechSynthesis.speaking
    ) {
      window.speechSynthesis.cancel();
    }
    // stop end

    this.state.countPage++;

    if (this.state.countPage > 10) {
      this.setState({ ...this.state, countPage: 10 });
    }

    let readerConfig = localStorage.getItem("readerConfig");
    let readerConfigObj = JSON.parse(readerConfig);

    if (readerConfigObj.rm === "p") {
      if (this.state.countPage >= 0 && this.state.countPage < 10) {
        this.nextPageControl();
      } else {
        // alert("試閱完");
        this.setState({ ...this.state, confirmOpenBookDialog: true });
      }
    } else {
      this.nextPageControl();
    }
  };

  prevPageControl = () => {
    if (lock) return;
    if (
      this.props.currentEpub.rendition.location &&
      this.props.currentEpub.rendition.location.atStart
    ) {
      return;
    }
    lock = false;
    this.state.rendition.prev().then(() => {
      try {
        _this.mo.pause();
        _this.mo2.pause();
      } catch (e) {}
      this.state.isPlaying = false;
      let _this = this;
      setTimeout(function () {
        if (_this.state.mediaOverlay) {
          _this.listenThisPage();
        }
      }, 500);
      lock = false;
      return false;
    });
  };

  prevPage = () => {
    // stop media overlay audio
    try {
      this.mo.pause();
    } catch (e) {}
    try {
      this.mo2.pause();
    } catch (e) {}
    // stop media overlay audio end
    
    // stop 
    if (
      window.speechSynthesis
      && window.speechSynthesis.speaking
    ) {
      window.speechSynthesis.cancel();
    }
    // stop end

    this.state.countPage--;

    if (this.state.countPage < 0) {
      this.setState({ ...this.state, countPage: 0 });
    }

    let readerConfig = localStorage.getItem("readerConfig");
    let readerConfigObj = JSON.parse(readerConfig);

    if (readerConfigObj.rm === "p") {
      if (this.state.countPage >= 0 && this.state.countPage <= 10) {
        this.prevPageControl();
      } else {
        // alert("試閱完");
        this.setState({ ...this.state, confirmOpenBookDialog: true });
      }
    } else {
      this.prevPageControl();
    }
  };
  /*
  backPage = () => {
    try {
      this.state.mo.pause();
    } catch (err) {
    }
    try {
      this.state.mo2.pause();
    } catch (err) {
    }
    try {
      if (window.speechSynthesis && window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
      }
    } catch (err) {
    }
    window.history.back();
  };
  */
  listenThisPage = () => {
    let _this = this;
    let a = new window.ePub.CFI();

    // check media overlay status
    if (_this.state.isPlaying) {
      console.log("[listenThisPage] CP1");
      // stop media overlay audio
      _this.state.isPlaying = false;
      try {
        _this.mo.pause();
      } catch (e1) {
        console.log("e1", e1);
      }
      try {
        _this.mo2.pause();
      } catch (e2) {
        console.log("e2", e2);
      }
      // stop media overlay audio end
      console.log("[listenThisPage] CP1 end");
      return;
    }
    // check media overlay status end

    try {
      let startCfi = _this.state.rendition.location.start.cfi
        .replace("epubcfi(", "")
        .replace(")", "");
      let cfiStart = startCfi.replace(/.*!/, "").replace(/\)$/, "");
      let manifestObjArray = Object.entries(
        _this.props.currentEpub.packaging.manifest
      );
      let filteredObj = manifestObjArray.filter(
        ([key, value]) =>
          (value.href.substring(0, value.href.lastIndexOf(".")) ===
            _this.state.rendition.location.start.href.substring(
              0,
              _this.state.rendition.location.start.href.lastIndexOf(".")
            ) ||
            value.href.substring(0, value.href.lastIndexOf(".")) ===
              _this.state.rendition.location.start.href) &&
          value.href.contains("smil")
      );
      let filteredObj2 = manifestObjArray.filter(
        ([key, value]) =>
          (value.href.substring(0, value.href.lastIndexOf(".")) ===
            _this.state.rendition.location.end.href.substring(
              0,
              _this.state.rendition.location.end.href.lastIndexOf(".")
            ) ||
            value.href.substring(0, value.href.lastIndexOf(".")) ===
              _this.state.rendition.location.end.href) &&
          value.href.contains("smil")
      );
      //console.log("smilsmil", filteredObj, filteredObj2);
      if (filteredObj.length > 0) {
        let urlCacheObj = _this.props.currentEpub.archive.urlCache;

        if (_this.state.mediaOverlay) {
          if (_this.state.isPlaying) {
            try {
              _this.mo.pause();
            } catch (e) {}
            try {
              _this.mo2.pause();
            } catch (e) {}
          } else {
            try {
              _this.mo.pause();
            } catch (e) {}
            try {
              _this.mo2.pause();
            } catch (e) {}
            try {
              _this.state.isPlaying = _this.mo.isPlaying();
            } catch (e) {}
            let splitItems = cfiStart.split("/");
            //console.log(splitItems);
            let lastSplitItem = splitItems[splitItems.length - 2];
            let regExp = /\[([^)]+)\]/;
            let matches = regExp.exec(lastSplitItem);
            let directory = _this.props.currentEpub.container.directory;
            //TODO HT double page smil issues
            //console.log("dirrrrr","/" + directory + "/" + filteredObj[0][1].href);

            let _afterPlayComplateCallback = null;
            // handle ltr rtl
            if (
              StorageUtil.getReaderConfig("readerMode") === "single" ||
              StorageUtil.getReaderConfig("readerMode") === "scroll"
            ) {
              
            } else {
              try {
                let _target = document.getElementsByClassName("epub-container")[0];
                let _flag = _target.dir === "ltr";
                if (_flag) {
                  _afterPlayComplateCallback = function() {
                    console.log("afterPlayComplateCallback in mo");
                    try {
                      if (_this.mo2) {
                        //_this.listenThisPage2();
                        let splitItems2 = cfiStart.split("/");
                        //console.log(splitItems);
                        let lastSplitItem2 = splitItems2[splitItems2.length - 2];
                        let regExp2 = /\[([^)]+)\]/;
                        let matches2 = regExp2.exec(lastSplitItem2);
                        _this.listenThisPage2(matches2);
                      }
                    } catch (err_afterPlayComplateCallback) {
                      console.log("err_afterPlayComplateCallback in mo", err_afterPlayComplateCallback);
                    }
                  };
                }
              } catch (err) {
                console.log("err", err);
              }
            }
            // handle ltr rtl end

            _this.mo = new window.MediaOverlay({
              smil_url:
                urlCacheObj["/" + directory + "/" + filteredObj[0][1].href],
              urlCacheObj: urlCacheObj,
              directory: directory,
              afterPlayComplateCallback: _afterPlayComplateCallback,
            }); //(filteredObj[0][1]).href], "urlCacheObj": urlCacheObj });
            _this.mo.initialize();
            _this.mo.fetch();
            setTimeout(function () {
              //console.log("mooooo", _this.mo);
              _this.state.mo = _this.mo;

              // handle ltr rtl
              if (
                StorageUtil.getReaderConfig("readerMode") === "single" ||
                StorageUtil.getReaderConfig("readerMode") === "scroll"
              ) {
                _this.listenThisPage1(matches);
              } else {
                try {
                  let _target = document.getElementsByClassName("epub-container")[0];
                  let _flag = _target.dir === "ltr";
                  if (_flag) {
                    _this.listenThisPage1(matches);
                  }
                } catch (err) {
                  console.log("err", err);
                }
              }
              // handle ltr rtl end
            }, 1000);
          }
        }
      }
      
      if (filteredObj2.length > 0) {
        let urlCacheObj = _this.props.currentEpub.archive.urlCache;

        if (_this.state.mediaOverlay) {
          if (_this.state.isPlaying) {
            try {
              _this.mo.pause();
            } catch (e) {}
            try {
              _this.mo2.pause();
            } catch (e) {}
          } else {
            try {
              _this.mo.pause();
            } catch (e) {}
            try {
              _this.mo2.pause();
            } catch (e) {}
            try {
              _this.state.isPlaying = _this.mo2.isPlaying();
            } catch (e) {}
            let splitItems = cfiStart.split("/");
            //console.log(splitItems);
            let lastSplitItem = splitItems[splitItems.length - 2];
            let regExp = /\[([^)]+)\]/;
            let matches = regExp.exec(lastSplitItem);
            let directory = _this.props.currentEpub.container.directory;
            //TODO HT double page smil issues
            //console.log("dirrrrr","/" + directory + "/" + filteredObj2[0][1].href);

            let _afterPlayComplateCallback = null;
            // handle ltr rtl
            if (
              StorageUtil.getReaderConfig("readerMode") === "single" ||
              StorageUtil.getReaderConfig("readerMode") === "scroll"
            ) {
              
            } else {
              try {
                let _target = document.getElementsByClassName("epub-container")[0];
                let _flag = _target.dir === "ltr";
                if (_flag) {
                } else {
                  _afterPlayComplateCallback = function() {
                    console.log("afterPlayComplateCallback in mo2");
                    try {
                      if (_this.mo) {
                        //_this.listenThisPage2();
                        let splitItems1 = cfiStart.split("/");
                        //console.log(splitItems);
                        let lastSplitItem1 = splitItems1[splitItems1.length - 2];
                        let regExp1 = /\[([^)]+)\]/;
                        let matches1 = regExp1.exec(lastSplitItem1);
                        _this.listenThisPage1(matches1);
                      }
                    } catch (err_afterPlayComplateCallback) {
                      console.log("err_afterPlayComplateCallback in mo2", err_afterPlayComplateCallback);
                    }
                  };
                }
              } catch (err) {
                console.log("err", err);
              }
            }
            // handle ltr rtl end

            _this.mo2 = new window.MediaOverlay({
              smil_url:
                urlCacheObj["/" + directory + "/" + filteredObj2[0][1].href],
              urlCacheObj: urlCacheObj,
              directory: directory,
              afterPlayComplateCallback: _afterPlayComplateCallback,
            }); //(filteredObj[0][1]).href], "urlCacheObj": urlCacheObj });
            _this.mo2.initialize();
            _this.mo2.fetch();
            setTimeout(function () {
              //console.log("moooo2", _this.mo2);
              _this.state.mo2 = _this.mo2;
              
              // handle ltr rtl
              if (
                StorageUtil.getReaderConfig("readerMode") === "single" ||
                StorageUtil.getReaderConfig("readerMode") === "scroll"
              ) {
                if (filteredObj.length > 0) {

                } else {
                  _this.listenThisPage2(matches);
                }
              } else {
                try {
                  let _target = document.getElementsByClassName("epub-container")[0];
                  let _flag = _target.dir === "ltr";
                  if (_flag) {
                    if (filteredObj.length > 0) {

                    } else {
                      _this.listenThisPage2(matches);
                    }
                  } else {
                    if (filteredObj2.length > 0) {
                      _this.listenThisPage2(matches);
                    }
                  }
                } catch (err) {
                  console.log("err", err);
                }
              }
              // handle ltr rtl end
            }, 1000);
          }
        }
      }

    } catch (err) {
      console.log("listenThisPage err", err)
    }
  };
  listenThisPage1 = (matches) => {
    console.log("listenThisPage1");
    let _this = this;
    try {
      let node = _this.mo.findNodeByTextSrc(
        _this.state.rendition.location.start.href.replace("Text/", "") +
          "#" +
          matches[1]
      );
      _this.mo.startPlayback(node);
    } catch (e) {
      //console.log(_this.state.rendition.location.start.href)
      if (getIframeDoc().getElementsByTagName("span") !== undefined) {
        let spanClassId =
          getIframeDoc().getElementsByTagName("span")[0].id;
        let node = _this.mo.findNodeByTextSrc(
          _this.state.rendition.location.start.href.replace(
            "Text/",
            ""
          ) +
            "#" +
            spanClassId
        );
        _this.mo.startPlayback(node);
      } else {
        _this.mo.startPlayback(null);
      }
    }
  }
  listenThisPage2 = (matches) => {
    let _this = this;
    try {
      let node = _this.mo2.findNodeByTextSrc(
        _this.state.rendition.location.end.href.replace("Text/", "") +
          "#" +
          matches[1]
      );
      _this.mo2.startPlayback(node);
    } catch (e) {
      //console.log(_this.state.rendition.location.start.href)
      if (getIframeDoc().getElementsByTagName("span") !== undefined) {
        try {
          let spanClassId =
            getIframeDoc().getElementsByTagName("span")[0].id;
          let node = _this.mo2.findNodeByTextSrc(
            _this.state.rendition.location.start.href.replace(
              "Text/",
              ""
            ) +
              "#" +
              spanClassId
          );
          _this.mo2.startPlayback(node);
        } catch (err_to_mo2) {
          console.log("err_to_mo2", err_to_mo2);
          if (
            getIframeDoc2(1).getElementsByTagName("span") !== undefined
          ) {
            let spanClassId =
              getIframeDoc2(1).getElementsByTagName("span")[0].id;
            let node = _this.mo2.findNodeByTextSrc(
              _this.state.rendition.location.end.href.replace("Text/", "") +
                "#" +
                spanClassId
            );
            _this.mo2.startPlayback(node);
          } else {
            _this.mo2.startPlayback(null);
          }
        }
      } else if (
        getIframeDoc2(1).getElementsByTagName("span") !== undefined
      ) {
        let spanClassId =
          getIframeDoc2(1).getElementsByTagName("span")[0].id;
        let node = _this.mo2.findNodeByTextSrc(
          _this.state.rendition.location.end.href.replace("Text/", "") +
            "#" +
            spanClassId
        );
        _this.mo2.startPlayback(node);
      } else {
        _this.mo2.startPlayback(null);
      }
    }
  };

  setIsPlaying = (_flag) => {
    this.state.isPlaying = _flag;
    //console.log("setIsPlaying, this.state.isPlaying", this.state.isPlaying);
  };

  getProgressPanel = () => {
    let readerConfig = localStorage.getItem("readerConfig");
    let readerConfigObj = JSON.parse(readerConfig);

    if (readerConfigObj.rm === "p") {
      return;
    } else {
      return (
        <div
          className="progress-panel-container"
          onMouseLeave={(event) => {
            this.handleLeaveReader("bottom");
          }}
          style={
            this.state.isOpenBottomPanel
              ? {}
              : {
                  // transform: "translateY(110px)",
                }
          }
        >
          <ProgressPanel {...{
            time: this.state.time
          }} />
        </div>
      );
    }
  };

  render() {
    //console.log("render", this.state);
    const renditionProps = {
      mediaOverlay: this.state.mo,
      mediaOverlay2: this.state.mo2,
      rendition: this.state.rendition,
      handleLeaveReader: this.handleLeaveReader,
      handleEnterReader: this.handleEnterReader,
      leftOrRight: 0,
      isShow:
        this.state.isOpenLeftPanel ||
        this.state.isOpenTopPanel ||
        this.state.isOpenBottomPanel ||
        this.state.isOpenRightPanel,
      setIsPlaying: this.setIsPlaying,
      handleRenderBook: this.handleRenderBook,
      time: this.state.time,
      handleSaveReaderLogNote: this.handleSaveReaderLogNote,
    };
    return (
      <div className="viewer">
        <div
          className={
            this.checkHasThisBookmark()
              ? "add-bookmark-button2 on"
              : "add-bookmark-button2"
          }
          onClick={() => {
            this.handleAddBookmark();
          }}
        >
          <span className="add-bookmark-text2">
            {this.checkHasThisBookmark() ? (
              <Trans>Remove Bookmark</Trans>
            ) : (
              <Trans>Add Bookmark</Trans>
            )}
          </span>
        </div>
        {this.state.mediaOverlay ? (
          <div
            className="icon-play"
            onClick={() => {
              this.listenThisPage();
            }}
          >
            {this.state.isPlaying ? "停止" : "播放"}
          </div>
        ) : (
          <></>
        )}
        {StorageUtil.getReaderConfig("isHidePageButton") !== "yes" && (
          <>
            <div
              className="previous-chapter-single-container"
              onClick={() => {
                this.prevPage();
              }}
            >
              <span className="icon-dropdown previous-chapter-single"></span>
            </div>
            <div
              className="next-chapter-single-container"
              onClick={() => {
                this.nextPage();
              }}
            >
              <span className="icon-dropdown next-chapter-single"></span>
            </div>
          </>
        )}
        {StorageUtil.getReaderConfig("isHideMenuButton") !== "yes" && (
          <div
            className="reader-setting-icon-container"
            onClick={() => {
              //this.handleEnterReader("left");
              this.handleEnterReader("right");
              this.handleEnterReader("bottom");
              this.handleEnterReader("top");
            }}
          >
            <span className="icon-grid reader-setting-icon"></span>
          </div>
        )}
        <div
          className="left-panel"
          onMouseEnter={() => {
            if (
              this.state.isTouch ||
              this.state.isOpenLeftPanel ||
              this.state.isPreventTrigger
            ) {
              this.setState({ hoverPanel: "left" });
              return;
            }
            //this.handleEnterReader("left");
          }}
          onMouseLeave={() => {
            this.setState({ hoverPanel: "" });
          }}
          style={this.state.hoverPanel === "left" ? { opacity: 0.5 } : {}}
          onClick={() => {
            this.handleEnterReader("left");
          }}
        >
          <span className="icon-grid panel-icon"></span>
        </div>
        <div
          className="right-panel"
          onMouseEnter={() => {
            if (
              this.state.isTouch ||
              this.state.isOpenRightPanel ||
              this.state.isPreventTrigger
            ) {
              this.setState({ hoverPanel: "right" });
              return;
            }
            //this.handleEnterReader("right");
          }}
          onMouseLeave={() => {
            this.setState({ hoverPanel: "" });
          }}
          style={this.state.hoverPanel === "right" ? { opacity: 0.5 } : {}}
          onClick={() => {
            this.handleEnterReader("right");
          }}
        >
          <span className="icon-grid panel-icon"></span>
        </div>
        {/* <div
      className="top-panel"
      onMouseEnter={() => {
        if (
          this.state.isTouch ||
          this.state.isOpenTopPanel ||
          this.state.isPreventTrigger
        ) {
          this.setState({ hoverPanel: "top" });
          return;
        }
        this.handleEnterReader("top");
      }}
      onMouseLeave={() => {
        this.setState({ hoverPanel: "" });
      }}
      style={this.state.hoverPanel === "top" ? { opacity: 0.5 } : {}}
      onClick={() => {
        this.handleEnterReader("top");
      }}
    >
      <span className="icon-grid panel-icon"></span>
    </div> */}
        <div
          className="bottom-panel"
          onMouseEnter={() => {
            if (
              this.state.isTouch ||
              this.state.isOpenBottomPanel ||
              this.state.isPreventTrigger
            ) {
              this.setState({ hoverPanel: "bottom" });
              return;
            }
            this.handleEnterReader("bottom");
          }}
          onMouseLeave={() => {
            this.setState({ hoverPanel: "" });
          }}
          style={this.state.hoverPanel === "bottom" ? { opacity: 0.5 } : {}}
          onClick={() => {
            this.handleEnterReader("bottom");
          }}
        >
          <span className="icon-grid panel-icon"></span>
        </div>

        {this.state.rendition && this.props.currentEpub.rendition && (
          <EpubViewer
            {...renditionProps}
            abc={this.state.mo}
            isPlaying={this.state.isPlaying}
          />
        )}
        <div
          className="setting-panel-container"
          onMouseLeave={(event) => {
            //this.handleLeaveReader("right");
          }}
          style={
            this.state.isOpenRightPanel
              ? {}
              : {
                  transform: "translateX(309px)",
                }
          }
        >
          <div
            className="setting-panel-container-handle"
            onClick={(event) => {
              if (this.state.isOpenRightPanel) {
                this.handleLeaveReader("right");
              } else {
                this.handleEnterReader("right");
              }
              //this.handleLeaveReader("right");
            }}
          >
            {this.state.isOpenRightPanel ? "▶" : "◀"}
          </div>
          <SettingPanel {...{ time: this.state.time }} />
        </div>
        <div
          className={
            this.state.isOpenLeftPanel
              ? "navigation-panel-container open"
              : "navigation-panel-container"
          }
          onMouseLeave={(event) => {
            //this.handleLeaveReader("left");
          }}
          style={
            this.state.isOpenLeftPanel
              ? {}
              : {
                  transform: "translateX(-309px)",
                }
          }
        >
          <div
            className="navigation-panel-container-handle"
            onClick={(event) => {
              if (this.state.isOpenLeftPanel) {
                this.handleLeaveReader("left");
              } else {
                this.handleEnterReader("left");
              }
              //this.handleLeaveReader("left");
            }}
          >
            {this.state.isOpenLeftPanel ? "◀" : "▶"}
          </div>
          <NavigationPanel
            {...{
              time: this.state.time,
              readerType: "EPUB",
              handleSaveReaderLogBookmark: this.handleSaveReaderLogBookmark,
              handleSaveReaderLogNote: this.handleSaveReaderLogNote,
            }}
          />
        </div>

        {this.getProgressPanel()}

        {/*
        <div
          className="operation-panel-container"
          onMouseLeave={(event) => {
            this.handleLeaveReader("top");
          }}
          style={
            this.state.isOpenTopPanel
              ? {}
              : {
                transform: "translateY(-110px)",
              }
          }
        >
          <OperationPanel {...{ time: this.state.time }} />
        </div>
        */}
        <div className="view-area-page-container">
          <div
            className="view-area-page"
            id="page-area"
            style={
              document.body.clientWidth < 570
                ? { left: 0, right: 0 }
                : this.state.readerMode === "scroll"
                  ? {
                    left: `calc(50vw - ${
                      270 * parseFloat(this.state.scale)
                    }px)`,
                    right: `calc(50vw - ${
                      270 * parseFloat(this.state.scale)
                    }px)`,
                    top: "75px",
                    bottom: "75px",
                  }
                  : this.state.readerMode === "single"
                    ? {
                      left: `calc(50vw - ${
                        270 * parseFloat(this.state.scale)
                      }px)`,
                      right: `calc(50vw - ${
                        270 * parseFloat(this.state.scale)
                      }px)`,
                    }
                    : this.state.readerMode === "double"
                      ? {}
                      : {}
            }
          >
            <span className="icon-grid reader-setting-icon"></span>
          </div>
        </div>
        {this.state.isHideBackground ? null : <Background />}

        <PageWidget {...{ time: this.state.time }} />

        <div id="tempPage" style={{ display: "none" }}></div>
        <DialogBox
          styleType={PATH.STYLETYPE_FOUR}
          title="試閱完"
          showDialog={this.state.confirmOpenBookDialog}
          children={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}
            >
              <button
                style={{
                  color: "white",
                  background:
                    "linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%)",
                  width: "100%",
                  borderRadius: "50px",
                  height: "35px",
                  maxWidth: "90px",
                  margin: "0 3px",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({
                    ...this.state,
                    confirmOpenBookDialog: false,
                  });
                }}
              >
                {/* <Trans>好</Trans> */}
                確定
              </button>
            </div>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // for CTS
  let _MemberReducer = state.MemberReducer;
  let _userInfo = {
    member: undefined
  };
  if (
    _MemberReducer
    && _MemberReducer.member_id
  ) {
    _userInfo = {
      member: {
        memberId: _MemberReducer.member_id,
      }
    }
  }
  // for CTS end

  return {
    currentEpub: state.book.currentEpub,
    currentBook: state.book.currentBook,
    bookmarks: state.reader.bookmarks,
    notes: state.reader.notes,
    books: state.manager.books,
    htmlBook: state.reader.htmlBook,
    locations: state.progressPanel.locations,
    flattenChapters: state.reader.flattenChapters,
    userInfo: _userInfo,
    readerLog: state.reader.readerLog,
  };
};
const actionCreator = {
  handleBookmarks,
  handleFetchNotes,
  handleFetchBookmarks,
  handleFetchChapters,
  handleFetchPercentage,
  handleFetchBooks,
  handleNotes,
  handleReaderMode,
  handleReaderLog,
  handleRenderFunc,
  handleShowBookmark,
};
export default connect(mapStateToProps, actionCreator)(withSocket(Reader));
