import { t } from "i18next";
import { useState } from "react";
import {
  resetPasswordEmail,
  resetPasswordSetMemberPassword,
  resetPasswordVerify,
} from "../../api/MemberAPI";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import style from "./ForgetPassword.module.css";

export default function ForgetPassword(props: any) {
  const EMAIL = "EMAIL",
    VERIFY = "VERIFY",
    AUTH = "AUTH";
  const [status, setStatus] = useState(EMAIL);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordVerify, setNewPasswordVerify] = useState("");

  const resetState = () => {
    setEmail("");
    setCode("");
    setNewPassword("");
    setNewPasswordVerify("");
    setStatus(EMAIL);
  };

  const closeDialog = () => {
    resetState();
    props.callback("close");
  };

  const verifyEmail = (resent: boolean = false) => {
    resetPasswordEmail({ email: email, reset: resent }).then((res: any) => {
      if (res.success) {
        setStatus(VERIFY);
        alert("已寄出驗證碼到你的電郵");
      } else if (res.message == "Email already send") {
        setStatus(VERIFY);
        alert("已寄出驗證碼到你的電郵");
      } else {
        alert("無效的電郵，請再嘗試");
      }
    });
  };

  const verifyCode = () => {
    resetPasswordVerify({ email: email, verifyCode: code }).then((res: any) => {
      if (res.success) {
        setStatus(AUTH);
      } else {
        alert("無效的驗證碼，請再嘗試");
      }
    });
  };

  const resetPassword = () => {
    if (newPassword !== newPasswordVerify) {
      return alert("Password not match!");
    }
    if (window.confirm("是否確定更改密碼?")) {
      resetPasswordSetMemberPassword({
        email: email,
        password: newPassword,
      }).then((res: any) => {
        if (res.success) {
          alert("更改成功");
          closeDialog();
        } else {
          alert("更改失敗，請再嘗試");
        }
      });
    }
  };

  return (
    <div className={style.base}>
      {status === AUTH ? (
        <>
          <div className={style.inputDiv}>
            <Input
              type="password"
              placeholder={"密碼"}
              styleType={"loginInput"}
              callBack={(value) => setNewPassword(value)}
            />
          </div>
          <div className={style.inputDiv}>
            <Input
              type="password"
              placeholder={"確定密碼"}
              styleType={"loginInput"}
              callBack={(value) => setNewPasswordVerify(value)}
            />
          </div>
          <div className={style.inputRow}>
            <Button
              buttonType={"button"}
              styleType={"loginButton"}
              callBack={(value) => resetPassword()}
            >
              {"儲存"}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className={style.inputRow}>
            <div className={style.inputDiv}>
              <Input
                type="text"
                placeholder={"signIn.emailAddress"}
                styleType={"loginInput"}
                callBack={(value) => setEmail(value)}
              />
            </div>
            {status === EMAIL ? (
              <Button
                buttonType={"button"}
                styleType={"loginButton"}
                callBack={(value) => verifyEmail()}
              >
                {"發送"}
              </Button>
            ) : (
              <Button
                buttonType={"button"}
                styleType={"loginButton"}
                callBack={(value) => verifyEmail(true)}
              >
                {"重新發送"}
              </Button>
            )}
          </div>
          {status === VERIFY ? (
            <div className={style.inputRow}>
              <div className={style.inputDiv}>
                <Input
                  type="text"
                  placeholder={"驗證碼"}
                  styleType={"loginInput"}
                  callBack={(value) => setCode(value.trim())}
                />
              </div>
              <Button
                buttonType={"button"}
                styleType={"loginButton"}
                callBack={(value) => verifyCode()}
              >
                {"驗證"}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      <div className={style.buttonGroup}>
        <div
          style={{
            width: "25px",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#47d5cd",
            borderRadius: "50%",
            fontWeight: "bold",
          }}
          onClick={() => closeDialog()}
        >
          X
        </div>
      </div>
    </div>
  );
}
