export const getCountryCodeData = (t: any) => {
  return [
    { value: "", display: "signUp.areaCode", locationId: 0, minDigits: 0, maxDigits: 0 },
    { value: "+86", display: "country.CN", locationId: 78, minDigits: 7, maxDigits: 11 },
    { value: "+852", display: "country.HK", locationId: 6, minDigits: 8, maxDigits: 8 },
    { value: "+886", display: "country.TW", locationId: 20, minDigits: 9, maxDigits: 10 },
    { value: "+853", display: "country.MO", locationId: 11, minDigits: 8, maxDigits: 8 },
    { value: "+65", display: "country.SG", locationId: 17, minDigits: 8, maxDigits: 8 },
    { value: "+81", display: "country.JP", locationId: 9, minDigits: 9, maxDigits: 10 },
    { value: "+66", display: "country.TH", locationId: 21, minDigits: 9, maxDigits: 10 },
    { value: "+60", display: "country.MY", locationId: 12, minDigits: 9, maxDigits: 10 },
    { value: "+63", display: "country.PH", locationId: 15, minDigits: 10, maxDigits: 10 },
    { value: "+61", display: "country.AU", locationId: 1, minDigits: 9, maxDigits: 10 },
    { value: "+61-8", display: "country.CX", locationId: 75, minDigits: 9, maxDigits: 10 },
    { value: "+61-891", display: "country.CC", locationId: 74, minDigits: 9, maxDigits: 10 },
    { value: "+1", display: "country.CA", locationId: 3, minDigits: 10, maxDigits: 10 },
    { value: "+44", display: "country.GB", locationId: 22, minDigits: 9, maxDigits: 10 },
    { value: "+1", display: "country.US", locationId: 23, minDigits: 10, maxDigits: 10 },
    { value: "+244", display: "country.AO", locationId: 116, minDigits: 9, maxDigits: 9 },
    { value: "+93", display: "country.AF", locationId: 82, minDigits: 9, maxDigits: 9 },
    { value: "+355", display: "country.AL", locationId: 150, minDigits: 9, maxDigits: 9 },
    { value: "+213", display: "country.DZ", locationId: 188, minDigits: 9, maxDigits: 9 },
    { value: "+376", display: "country.AD", locationId: 162, minDigits: 9, maxDigits: 9 },
    { value: "+1-264", display: "country.AI", locationId: 32, minDigits: 10, maxDigits: 10 },
    { value: "+1-268", display: "country.AG", locationId: 33, minDigits: 10, maxDigits: 10 },
    { value: "+54", display: "country.AR", locationId: 69, minDigits: 10, maxDigits: 10 },
    { value: "+374", display: "country.AM", locationId: 160, minDigits: 9, maxDigits: 9 },
    { value: "+247", display: "country.AC", locationId: 118, minDigits: 9, maxDigits: 9 },
    { value: "+43", display: "country.AT", locationId: 2, minDigits: 10, maxDigits: 13 },
    { value: "+994", display: "country.AZ", locationId: 243, minDigits: 9, maxDigits: 9 },
    { value: "+1-242", display: "country.BS", locationId: 27, minDigits: 10, maxDigits: 10 },
    { value: "+973", display: "country.BH", locationId: 236, minDigits: 9, maxDigits: 9 },
    { value: "+880", display: "country.BD", locationId: 224, minDigits: 9, maxDigits: 9 },
    { value: "+1-246", display: "country.BB", locationId: 26, minDigits: 10, maxDigits: 10 },
    { value: "+375", display: "country.BY", locationId: 161, minDigits: 9, maxDigits: 9 },
    { value: "+7", display: "country.RU", locationId: 48, minDigits: 9, maxDigits: 9 },
    { value: "+501", display: "country.BZ", locationId: 177, minDigits: 9, maxDigits: 9 },
    { value: "+229", display: "country.BJ", locationId: 100, minDigits: 9, maxDigits: 9 },
    { value: "+1-441", display: "country.BM", locationId: 34, minDigits: 10, maxDigits: 10 },
    { value: "+591", display: "country.BO", locationId: 189, minDigits: 9, maxDigits: 9 },
    { value: "+267", display: "country.BW", locationId: 138, minDigits: 9, maxDigits: 9 },
    { value: "+55", display: "country.BR", locationId: 70, minDigits: 10, maxDigits: 11 },
    { value: "+673", display: "country.BN", locationId: 202, minDigits: 9, maxDigits: 9 },
    { value: "+359", display: "country.BG", locationId: 155, minDigits: 9, maxDigits: 9 },
    { value: "+226", display: "country.BF", locationId: 97, minDigits: 9, maxDigits: 9 },
    { value: "+95", display: "country.MM", locationId: 84, minDigits: 9, maxDigits: 9 },
    { value: "+257", display: "country.BI", locationId: 128, minDigits: 9, maxDigits: 9 },
    { value: "+237", display: "country.CM", locationId: 108, minDigits: 9, maxDigits: 9 },
    { value: "+1-345", display: "country.KY", locationId: 42, minDigits: 10, maxDigits: 10 },
    { value: "+236", display: "country.CF", locationId: 107, minDigits: 9, maxDigits: 9 },
    { value: "+235", display: "country.TD", locationId: 106, minDigits: 9, maxDigits: 9 },
    { value: "+56", display: "country.CL", locationId: 71, minDigits: 9, maxDigits: 9 },
    { value: "+57", display: "country.CO", locationId: 72, minDigits: 10, maxDigits: 10 },
    { value: "+242", display: "country.CG", locationId: 113, minDigits: 9, maxDigits: 9 },
    { value: "+682", display: "country.CK", locationId: 211, minDigits: 9, maxDigits: 9 },
    { value: "+506", display: "country.CR", locationId: 182, minDigits: 9, maxDigits: 9 },
    { value: "+53", display: "country.CU", locationId: 68, minDigits: 9, maxDigits: 9 },
    { value: "+357", display: "country.CY", locationId: 152, minDigits: 9, maxDigits: 9 },
    { value: "+420", display: "country.CZ", locationId: 173, minDigits: 9, maxDigits: 9 },
    { value: "+45", display: "country.DK", locationId: 61, minDigits: 9, maxDigits: 9 },
    { value: "+253", display: "country.DJ", locationId: 124, minDigits: 9, maxDigits: 9 },
    { value: "+1-809", display: "country.DO", locationId: 31, minDigits: 10, maxDigits: 10 },
    { value: "+593", display: "country.EC", locationId: 191, minDigits: 9, maxDigits: 9 },
    { value: "+20", display: "country.EG", locationId: 50, minDigits: 7, maxDigits: 10 },
    { value: "+503", display: "country.SV", locationId: 179, minDigits: 9, maxDigits: 9 },
    { value: "+372", display: "country.EE", locationId: 158, minDigits: 9, maxDigits: 9 },
    { value: "+251", display: "country.ET", locationId: 122, minDigits: 9, maxDigits: 9 },
    { value: "+679", display: "country.FJ", locationId: 208, minDigits: 9, maxDigits: 9 },
    { value: "+358", display: "country.FI", locationId: 153, minDigits: 6, maxDigits: 10 },
    { value: "+33", display: "country.FR", locationId: 4, minDigits: 9, maxDigits: 9 },
    { value: "+594", display: "country.GF", locationId: 192, minDigits: 9, maxDigits: 9 },
    { value: "+241", display: "country.GA", locationId: 112, minDigits: 9, maxDigits: 9 },
    { value: "+220", display: "country.GM", locationId: 91, minDigits: 9, maxDigits: 9 },
    { value: "+995", display: "country.GE", locationId: 244, minDigits: 9, maxDigits: 9 },
    { value: "+49", display: "country.DE", locationId: 5, minDigits: 10, maxDigits: 11 },
    { value: "+233", display: "country.GH", locationId: 104, minDigits: 9, maxDigits: 9 },
    { value: "+350", display: "country.GI", locationId: 146, minDigits: 9, maxDigits: 9 },
    { value: "+30", display: "country.GR", locationId: 52, minDigits: 10, maxDigits: 10 },
    { value: "+1-473", display: "country.GD", locationId: 39, minDigits: 10, maxDigits: 10 },
    { value: "+1-671", display: "country.GU", locationId: 47, minDigits: 10, maxDigits: 10 },
    { value: "+502", display: "country.GT", locationId: 178, minDigits: 9, maxDigits: 9 },
    { value: "+224", display: "country.GN", locationId: 95, minDigits: 9, maxDigits: 9 },
    { value: "+592", display: "country.GY", locationId: 190, minDigits: 9, maxDigits: 9 },
    { value: "+509", display: "country.HT", locationId: 185, minDigits: 9, maxDigits: 9 },
    { value: "+504", display: "country.HN", locationId: 180, minDigits: 9, maxDigits: 9 },
    { value: "+36", display: "country.HU", locationId: 55, minDigits: 9, maxDigits: 9 },
    { value: "+354", display: "country.IS", locationId: 149, minDigits: 9, maxDigits: 9 },
    { value: "+91", display: "country.IN", locationId: 80, minDigits: 8, maxDigits: 10 },
    { value: "+62", display: "country.ID", locationId: 7, minDigits: 9, maxDigits: 9 },
    { value: "+98", display: "country.IR", locationId: 85, minDigits: 9, maxDigits: 9 },
    { value: "+964", display: "country.IQ", locationId: 229, minDigits: 9, maxDigits: 9 },
    { value: "+353", display: "country.IE", locationId: 148, minDigits: 9, maxDigits: 9 },
    { value: "+972", display: "country.IL", locationId: 235, minDigits: 9, maxDigits: 9 },
    { value: "+39", display: "country.IT", locationId: 8, minDigits: 9, maxDigits: 11 },
    { value: "+225", display: "country.CI", locationId: 96, minDigits: 9, maxDigits: 9 },
    { value: "+1-876", display: "country.JM", locationId: 28, minDigits: 10, maxDigits: 10 },
    { value: "+962", display: "country.JO", locationId: 227, minDigits: 9, maxDigits: 9 },
    { value: "+855", display: "country.KH", locationId: 222, minDigits: 9, maxDigits: 9 },
    { value: "+7", display: "country.KZ", locationId: 49, minDigits: 9, maxDigits: 9 },
    { value: "+254", display: "country.KE", locationId: 125, minDigits: 9, maxDigits: 9 },
    { value: "+82", display: "country.KR", locationId: 10, minDigits: 10, maxDigits: 11 },
    { value: "+850", display: "country.KP", locationId: 221, minDigits: 9, maxDigits: 9 },
    { value: "+47", display: "country.NO", locationId: 63, minDigits: 8, maxDigits: 8 },
    { value: "+968", display: "country.OM", locationId: 233, minDigits: 9, maxDigits: 9 },
    { value: "+92", display: "country.PK", locationId: 81, minDigits: 9, maxDigits: 9 },
    { value: "+507", display: "country.PA", locationId: 183, minDigits: 9, maxDigits: 9 },
    { value: "+675", display: "country.PG", locationId: 204, minDigits: 9, maxDigits: 9 },
    { value: "+595", display: "country.PY", locationId: 193, minDigits: 9, maxDigits: 9 },
    { value: "+51", display: "country.PE", locationId: 66, minDigits: 9, maxDigits: 9 },
    { value: "+48", display: "country.PL", locationId: 65, minDigits: 9, maxDigits: 9 },
    { value: "+689", display: "country.PF", locationId: 217, minDigits: 9, maxDigits: 9 },
    { value: "+351", display: "country.PT", locationId: 16, minDigits: 9, maxDigits: 9 },
    { value: "+1-787", display: "country.PR", locationId: 35, minDigits: 10, maxDigits: 10 },
    { value: "+974", display: "country.QA", locationId: 237, minDigits: 9, maxDigits: 9 },
    { value: "+262", display: "country.RE", locationId: 132, minDigits: 9, maxDigits: 9 },
    { value: "+40", display: "country.RO", locationId: 57, minDigits: 9, maxDigits: 9 },
    { value: "+32", display: "country.BE", locationId: 53, minDigits: 9, maxDigits: 9 },
    { value: "+965", display: "country.KW", locationId: 230, minDigits: 9, maxDigits: 9 },
    { value: "+996", display: "country.KG", locationId: 245, minDigits: 9, maxDigits: 9 },
    { value: "+856", display: "country.LA", locationId: 223, minDigits: 9, maxDigits: 9 },
    { value: "+371", display: "country.LV", locationId: 157, minDigits: 9, maxDigits: 9 },
    { value: "+961", display: "country.LB", locationId: 226, minDigits: 9, maxDigits: 9 },
    { value: "+266", display: "country.LS", locationId: 137, minDigits: 9, maxDigits: 9 },
    { value: "+231", display: "country.LR", locationId: 102, minDigits: 9, maxDigits: 9 },
    { value: "+218", display: "country.LY", locationId: 90, minDigits: 9, maxDigits: 9 },
    { value: "+423", display: "country.LI", locationId: 174, minDigits: 9, maxDigits: 9 },
    { value: "+370", display: "country.LT", locationId: 156, minDigits: 9, maxDigits: 9 },
    { value: "+352", display: "country.LU", locationId: 147, minDigits: 9, maxDigits: 9 },
    { value: "+261", display: "country.MG", locationId: 131, minDigits: 9, maxDigits: 9 },
    { value: "+265", display: "country.MW", locationId: 136, minDigits: 9, maxDigits: 9 },
    { value: "+960", display: "country.MV", locationId: 225, minDigits: 9, maxDigits: 9 },
    { value: "+223", display: "country.ML", locationId: 94, minDigits: 9, maxDigits: 9 },
    { value: "+356", display: "country.MT", locationId: 151, minDigits: 9, maxDigits: 9 },
    { value: "+1-670", display: "country.MP", locationId: 29, minDigits: 10, maxDigits: 10 },
    { value: "+596", display: "country.MQ", locationId: 194, minDigits: 9, maxDigits: 9 },
    { value: "+230", display: "country.MU", locationId: 101, minDigits: 9, maxDigits: 9 },
    { value: "+52", display: "country.MX", locationId: 67, minDigits: 9, maxDigits: 9 },
    { value: "+373", display: "country.MD", locationId: 159, minDigits: 9, maxDigits: 9 },
    { value: "+377", display: "country.MC", locationId: 163, minDigits: 9, maxDigits: 9 },
    { value: "+212", display: "country.MA", locationId: 87, minDigits: 9, maxDigits: 9 },
    { value: "+976", display: "country.MN", locationId: 239, minDigits: 9, maxDigits: 9 },
    { value: "+1-664", display: "country.MS", locationId: 46, minDigits: 10, maxDigits: 10 },
    { value: "+258", display: "country.MZ", locationId: 129, minDigits: 9, maxDigits: 9 },
    { value: "+264", display: "country.NA", locationId: 135, minDigits: 9, maxDigits: 9 },
    { value: "+674", display: "country.NR", locationId: 203, minDigits: 9, maxDigits: 9 },
    { value: "+977", display: "country.NP", locationId: 240, minDigits: 9, maxDigits: 9 },
    { value: "+599", display: "country.AN", locationId: 199, minDigits: 9, maxDigits: 9 },
    { value: "+31", display: "country.NL", locationId: 13, minDigits: 9, maxDigits: 9 },
    { value: "+64", display: "country.NZ", locationId: 14, minDigits: 8, maxDigits: 10 },
    { value: "+505", display: "country.NI", locationId: 181, minDigits: 9, maxDigits: 9 },
    { value: "+227", display: "country.NE", locationId: 98, minDigits: 9, maxDigits: 9 },
    { value: "+234", display: "country.NG", locationId: 105, minDigits: 10, maxDigits: 10 },
    { value: "+1-758", display: "country.LC", locationId: 45, minDigits: 10, maxDigits: 10 },
    { value: "+1-784", display: "country.VC", locationId: 43, minDigits: 10, maxDigits: 10 },
    { value: "+1-684", display: "country.AS", locationId: 37, minDigits: 10, maxDigits: 10 },
    { value: "+685", display: "country.WS", locationId: 213, minDigits: 9, maxDigits: 9 },
    { value: "+378", display: "country.SM", locationId: 164, minDigits: 9, maxDigits: 9 },
    { value: "+239", display: "country.ST", locationId: 110, minDigits: 9, maxDigits: 9 },
    { value: "+966", display: "country.SA", locationId: 231, minDigits: 9, maxDigits: 9 },
    { value: "+221", display: "country.SN", locationId: 92, minDigits: 9, maxDigits: 9 },
    { value: "+248", display: "country.SC", locationId: 119, minDigits: 9, maxDigits: 9 },
    { value: "+232", display: "country.SL", locationId: 103, minDigits: 9, maxDigits: 9 },
    { value: "+421", display: "country.SK", locationId: 174, minDigits: 9, maxDigits: 9 },
    { value: "+386", display: "country.SI", locationId: 170, minDigits: 9, maxDigits: 9 },
    { value: "+677", display: "country.SB", locationId: 206, minDigits: 9, maxDigits: 9 },
    { value: "+252", display: "country.SO", locationId: 123, minDigits: 9, maxDigits: 9 },
    { value: "+27", display: "country.ZA", locationId: 51, minDigits: 9, maxDigits: 9 },
    { value: "+34", display: "country.ES", locationId: 18, minDigits: 9, maxDigits: 9 },
    { value: "+94", display: "country.LK", locationId: 83, minDigits: 9, maxDigits: 9 },
    { value: "+249", display: "country.SD", locationId: 120, minDigits: 9, maxDigits: 9 },
    { value: "+597", display: "country.SR", locationId: 195, minDigits: 9, maxDigits: 9 },
    { value: "+268", display: "country.SZ", locationId: 139, minDigits: 9, maxDigits: 9 },
    { value: "+46", display: "country.SE", locationId: 62, minDigits: 7, maxDigits: 9 },
    { value: "+41", display: "country.CH", locationId: 19, minDigits: 9, maxDigits: 9 },
    { value: "+963", display: "country.SY", locationId: 228, minDigits: 9, maxDigits: 9 },
    { value: "+992", display: "country.TJ", locationId: 241, minDigits: 9, maxDigits: 9 },
    { value: "+255", display: "country.TZ", locationId: 126, minDigits: 9, maxDigits: 9 },
    { value: "+228", display: "country.TG", locationId: 99, minDigits: 9, maxDigits: 9 },
    { value: "+676", display: "country.TO", locationId: 205, minDigits: 9, maxDigits: 9 },
    { value: "+1-868", display: "country.TT", locationId: 25, minDigits: 10, maxDigits: 10 },
    { value: "+216", display: "country.TN", locationId: 89, minDigits: 9, maxDigits: 9 },
    { value: "+90", display: "country.TR", locationId: 79, minDigits: 9, maxDigits: 9 },
    { value: "+993", display: "country.TM", locationId: 242, minDigits: 9, maxDigits: 9 },
    { value: "+256", display: "country.UG", locationId: 127, minDigits: 9, maxDigits: 9 },
    { value: "+380", display: "country.UA", locationId: 166, minDigits: 9, maxDigits: 9 },
    { value: "+971", display: "country.AE", locationId: 234, minDigits: 9, maxDigits: 9 },
    { value: "+598", display: "country.UY", locationId: 196, minDigits: 9, maxDigits: 9 },
    { value: "+998", display: "country.UZ", locationId: 246, minDigits: 9, maxDigits: 9 },
    { value: "+58", display: "country.VE", locationId: 73, minDigits: 10, maxDigits: 10 },
    { value: "+84", display: "country.VN", locationId: 24, minDigits: 9, maxDigits: 10 },
    { value: "+967", display: "country.YE", locationId: 232, minDigits: 9, maxDigits: 9 },
    { value: "+38", display: "country.YU", locationId: 56, minDigits: 9, maxDigits: 9 },
    { value: "+263", display: "country.ZW", locationId: 134, minDigits: 9, maxDigits: 9 },
    { value: "+243", display: "country.ZR", locationId: 114, minDigits: 9, maxDigits: 9 },
    { value: "+243", display: "country.CD", locationId: 115, minDigits: 9, maxDigits: 9 },
    { value: "+260", display: "country.ZM", locationId: 130, minDigits: 9, maxDigits: 9 },
    { value: "+44", display: "country.JE", locationId: 60, minDigits: 9, maxDigits: 10 },
    { value: "+44-1481", display: "country.GG", locationId: 58, minDigits: 9, maxDigits: 10 },
    { value: "+44-1624", display: "country.IM", locationId: 59, minDigits: 9, maxDigits: 10 },
    { value: "+358", display: "country.AX", locationId: 154, minDigits: 9, maxDigits: 9 },
    { value: "+47", display: "country.SJ", locationId: 64, minDigits: 8, maxDigits: 8 },
    { value: "+262", display: "country.YT", locationId: 133, minDigits: 9, maxDigits: 9 },
    { value: "+1-767", display: "country.DM", locationId: 30, minDigits: 10, maxDigits: 10 },
    { value: "+382", display: "country.ME", locationId: 168, minDigits: 9, maxDigits: 9 },
    { value: "+1-284", display: "country.VG", locationId: 38, minDigits: 10, maxDigits: 10 },
    { value: "+1-721", display: "country.AN / MF / SX", locationId: 41, minDigits: 10, maxDigits: 10 },
    { value: "+1-869", display: "country.KN", locationId: 44, minDigits: 10, maxDigits: 10 },
    { value: "+1-649", display: "country.TC", locationId: 40, minDigits: 10, maxDigits: 10 },
    { value: "+1-340", display: "country.VI / US", locationId: 36, minDigits: 10, maxDigits: 10 },
    { value: "+211", display: "country.SS", locationId: 86, minDigits: 9, maxDigits: 9 },
    { value: "+222", display: "country.MR", locationId: 93, minDigits: 9, maxDigits: 9 },
    { value: "+238", display: "country.CV", locationId: 109, minDigits: 9, maxDigits: 9 },
    { value: "+240", display: "country.GQ", locationId: 111, minDigits: 9, maxDigits: 9 },
    { value: "+245", display: "country.GW", locationId: 117, minDigits: 9, maxDigits: 9 },
    { value: "+269", display: "country.KM", locationId: 140, minDigits: 9, maxDigits: 9 },
    { value: "+290", display: "country.SH", locationId: 141, minDigits: 9, maxDigits: 9 },
    { value: "+291", display: "country.ER", locationId: 142, minDigits: 9, maxDigits: 9 },
    { value: "+297", display: "country.AW", locationId: 143, minDigits: 9, maxDigits: 9 },
    { value: "+298", display: "country.FO", locationId: 144, minDigits: 9, maxDigits: 9 },
    { value: "+299", display: "country.GL", locationId: 145, minDigits: 9, maxDigits: 9 },
    { value: "+385", display: "country.HR", locationId: 169, minDigits: 9, maxDigits: 9 },
    { value: "+387", display: "country.BA", locationId: 171, minDigits: 9, maxDigits: 9 },
    { value: "+389", display: "country.MK", locationId: 172, minDigits: 9, maxDigits: 9 },
    { value: "+508", display: "country.PM", locationId: 184, minDigits: 9, maxDigits: 9 },
    { value: "+590", display: "country.FR", locationId: 54, minDigits: 9, maxDigits: 9 },
    { value: "+590", display: "country.ST", locationId: 110, minDigits: 9, maxDigits: 9 },
    { value: "+590", display: "country.BL / FR", locationId: 187, minDigits: 9, maxDigits: 9 },
    { value: "+599-3", display: "country.AN / NL", locationId: 199, minDigits: 9, maxDigits: 9 },
    { value: "+599-4", display: "country.AN / NL", locationId: 199, minDigits: 9, maxDigits: 9 },
    { value: "+599-7", display: "country.AN / BQ / NL", locationId: 200, minDigits: 9, maxDigits: 9 },
    { value: "+599-9", display: "country.CW / AN", locationId: 198, minDigits: 9, maxDigits: 9 },
    { value: "+670", display: "country.TL", locationId: 201, minDigits: 9, maxDigits: 9 },
    { value: "+680", display: "country.PW", locationId: 209, minDigits: 9, maxDigits: 9 },
    { value: "+681", display: "country.WF", locationId: 210, minDigits: 9, maxDigits: 9 },
    { value: "+683", display: "country.NU", locationId: 213, minDigits: 9, maxDigits: 9 },
    { value: "+686", display: "country.KI", locationId: 214, minDigits: 9, maxDigits: 9 },
    { value: "+687", display: "country.NC", locationId: 215, minDigits: 9, maxDigits: 9 },
    { value: "+688", display: "country.TV", locationId: 216, minDigits: 9, maxDigits: 9 },
    { value: "+690", display: "country.TK", locationId: 218, minDigits: 9, maxDigits: 9 },
    { value: "+691", display: "country.FM", locationId: 219, minDigits: 9, maxDigits: 9 },
    { value: "+692", display: "country.MH", locationId: 220, minDigits: 9, maxDigits: 9 },
    { value: "+975", display: "country.BT", locationId: 238, minDigits: 9, maxDigits: 9 },
    { value: "+381", display: "country.RS", locationId: 167, minDigits: 9, maxDigits: 9 },
    { value: "+678", display: "country.VU", locationId: 207, minDigits: 9, maxDigits: 9 },
    { value: "+500", display: "country.FK", locationId: 176, minDigits: 9, maxDigits: 9 },
    { value: "+250", display: "country.RW", locationId: 121, minDigits: 9, maxDigits: 9 },
    { value: "+379", display: "country.VA", locationId: 165, minDigits: 9, maxDigits: 9 }
  ];
};